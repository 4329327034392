import { useState, useCallback, useMemo, FC } from 'react'
import { faMagnifyingGlass, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { IconButton, InputBase } from '@mui/material'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'

type SimpleSearchBarProps = {
  onChange: (value: string) => void
  debounceTime?: number
}
const SimpleSearchBar: FC<SimpleSearchBarProps> = ({ onChange, debounceTime = 300 }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')

  const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [onChange, debounceTime])

  const handleSearch = useCallback(
    (value: string) => {
      debouncedOnChange(value)
    },
    [debouncedOnChange]
  )

  return (
    <InputBase
      fullWidth
      value={searchValue}
      className={classes.searchInput}
      endAdornment={
        searchValue ? (
          <IconButton
            size="small"
            onClick={() => {
              onChange('')
              setSearchValue('')
            }}
          >
            <FontAwesomeIcon icon={faX as IconProp} width={16} />
          </IconButton>
        ) : (
          <IconButton size="small">
            <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={16} />
          </IconButton>
        )
      }
      placeholder={t('searchInput.search')}
      onChange={(e) => {
        const value = e.target.value
        setSearchValue(value)
        handleSearch(value)
      }}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    searchInput: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2),
      marginTop: theme.spacing(1),
      maxHeight: 45,
    },
  })
)

export default SimpleSearchBar
