import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ServerError } from '../types'
import { API_URL } from '../constants'

type DeleteMemberRequest = {
  id: string
}

type DeleteMemberResponse = {
  message: boolean
}

const deleteMember = async ({ id }: DeleteMemberRequest) => {
  const response = await axios.delete<DeleteMemberResponse>(`${API_URL}/members/${id}/`, {
    withCredentials: true,
  })
  return response.data
}

const useDeleteMember = () => {
  const queryClient = useQueryClient()
  return useMutation<DeleteMemberResponse, ServerError, DeleteMemberRequest>({
    mutationFn: deleteMember,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
    ...{
      throwOnError: true,
    },
  })
}
export default useDeleteMember
