import { Box, styled, Switch, Tooltip } from '@mui/material'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import settingsPageImage from '@/assets/images/SettingsPage/SettingsPageImage.svg'
// @ts-ignore
import * as Yup from 'yup'

import OrganizationSettings from './OrganizationSettings'
import ResetPassword from './ResetPassword'
import RedeemPromoCode from './RedeemPromoCode'
import OrganizationCapacityBar from './OrganizationCapacityBar'
import { useUpdateClient } from '@/api/client/update-client'
import useToast from '@/common/hooks/useToast'
import { ClientDetailsResult } from '@/api/client/client'
import { FieldWrapper, SubTitle } from './ConfigurationsStyledComponents'

export type ConfigurationsProps = {
  organiztion?: ClientDetailsResult
}

const ConfigurationsTab: FC<ConfigurationsProps> = ({ organiztion }) => {
  const { t } = useTranslation()
  const [updateClient] = useUpdateClient()
  const [allowUnverifiedDomains, setAllowUnverifiedDomains] = useState(organiztion?.allow_unverified_domains)
  const { successToast, errorToast } = useToast()

  const handleUnverifiedDomainsChange = async () => {
    try {
      await updateClient({ allow_unverified_domains: !allowUnverifiedDomains })
      setAllowUnverifiedDomains((e) => !e)
      successToast(t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomainChanged'))
    } catch (e) {
      errorToast(t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomainChangeFailed'))
    }
  }
  return (
    <Root>
      <Top>
        <LeftBox>
          <OrganizationSettings
            name={organiztion?.name}
            country={organiztion?.origin_country}
            logo={organiztion?.logo}
          />
          <ResetPassword organiztion={organiztion}></ResetPassword>
          <RedeemPromoCode />
        </LeftBox>
        <RightBox>
          <img
            src={settingsPageImage}
            style={{ width: 530, margin: 'auto', height: 690 }}
            alt={t('settings.configurationTab.imageAlt')}
          />
        </RightBox>
      </Top>
      <OrganizationCapacityBar organiztion={organiztion}></OrganizationCapacityBar>
      <FieldWrapper>
        <SubTitle> {t('settings.configurationTab.unverifiedDomain.allowUnverifiedDomains')}</SubTitle>
        <UnverifiedSwitch disabled checked={allowUnverifiedDomains} onChange={handleUnverifiedDomainsChange} />
        <Tooltip title={t('settings.configurationTab.unverifiedDomain.tooltip')} placement="top">
          <FontAwesomeIcon icon={faCircleInfo as IconProp} color="#979797" fontSize={20} />
        </Tooltip>
      </FieldWrapper>
    </Root>
  )
}

const UnverifiedSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: '#05DBF2',
  },
  '& .MuiSwitch-switchBase.Mui-disabled .MuiSwitch-thumb': {
    color: '#05DBF2',
  },
}))
const Top = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))
const LeftBox = styled(Box)(() => ({
  minWidth: '300px',
  width: '50%',
  paddingRight: '45px',
}))
const RightBox = styled(Box)(() => ({
  display: 'flex',
  width: '50%',
}))
const Root = styled(Box)(() => ({
  fontFamily: 'Montserrat',
  justifyContent: 'space-between',
}))

export default ConfigurationsTab
