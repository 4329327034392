import { Children, isValidElement } from 'react'
import Box from '@mui/material/Box'
import MuiStep from '@mui/material/Step'
import { styled } from '@mui/material/styles'
import MuiStepper from '@mui/material/Stepper'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import MuiStepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { theme } from '@/theme/theme'

interface StepIconProps {
  icon?: React.ElementType
  className?: string
}

function StepIcon({ icon: IconComponent, ...props }: StepIconProps) {
  return (
    <Box
      {...props}
      sx={{
        zIndex: 1,
        color: theme.palette.white,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.black,
      }}>
      {IconComponent && <IconComponent />}
    </Box>
  )
}

interface StepProps {
  label: string | JSX.Element
  icon?: React.ElementType
  children?: React.ReactNode
  index?: number
  active: boolean
}

export function Step({ label, icon, children, ...other }: StepProps) {
  const { index, active } = other

  return (
    active && (
      <Box sx={{ position: 'relative' }}>
        {index === 0 && <StepConnector />}
        <MuiStep key={label} {...other}>
          <StepLabel
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: '18px',
              },
            }}
            StepIconComponent={(props) => <StepIcon {...props} icon={icon} />}>
            {label}
          </StepLabel>
          <StepContent sx={{ mb: '10px', ml: '32px', border: 'none' }}>{children}</StepContent>
        </MuiStep>
      </Box>
    )
  )
}

interface StepperProps {
  children: React.ReactNode
}

export function Stepper({ children }: StepperProps) {
  const activeChildren = Children.toArray(children).filter((child) => isValidElement(child) && child.props.active)

  return (
    <Box>
      <MuiStepper sx={{ overflow: 'hidden' }} orientation="vertical" connector={<StepConnector />}>
        {children}
      </MuiStepper>
      {activeChildren.length > 1 && (
        <Box sx={{ ml: '18px', position: 'relative' }}>
          <Box
            sx={{
              width: '10%',
              height: '35px',
              borderLeft: '3px solid black',
              borderBottom: '3px solid black',
              borderBottomLeftRadius: '50px',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              marginLeft: '50px',
              width: '65%',
              height: '3px',
              background:
                'linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0) 100%)',
            }}
          />
        </Box>
      )}
    </Box>
  )
}

const StepConnector = styled(MuiStepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    position: 'absolute',
    height: '100%',
    width: '40px',
    top: '25px',
    margin: 0,
  },
  [`& .${stepConnectorClasses.line}`]: {
    position: 'absolute',
    left: 'calc(50% - 2px)',
    height: '100%',
    width: 3,
    border: 0,
    backgroundColor: theme.palette.black,
    borderRadius: 1,
  },
}))
