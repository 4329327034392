import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MergeAllIcon from '../../../../assets/images/merge-cell.svg'
import MergeOneIcon from '../../../../assets/images/merge-one.svg'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type MergeAllDuplicatesProps = {
  duplicateUserCount: number
  mergeAll: () => void
  showSelectEach: () => void
}

export default function MergeAllDuplicates({ duplicateUserCount, mergeAll, showSelectEach }: MergeAllDuplicatesProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <FontAwesomeIcon icon={faTriangleExclamation as IconProp} className={classes.icon} />
        <Typography variant={'subtitle1'}>
          {t('bulkUserUpload.errors.duplicatesInDbAlert.message', {
            count: duplicateUserCount,
          })}
        </Typography>
      </div>
      <div className={classes.buttonsContainer}>
        <Button onClick={mergeAll} startIcon={<img src={MergeAllIcon} alt="Merge All" height={20} />}>
          {t('bulkUserUpload.errors.duplicatesInDbAlert.mergeAll')}
        </Button>
        <Button onClick={showSelectEach} startIcon={<img src={MergeOneIcon} alt="Merge Each" height={26} />}>
          {t('bulkUserUpload.errors.duplicatesInDbAlert.mergeEach')}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    flex: { display: 'flex', alignItems: 'center' },
    icon: { marginRight: theme.spacing(2), color: theme.palette.blueGray[900] },
    buttonsContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  })
)
