import {
  IntegrationType,
  MicrosoftEntraIdIntegrationType,
  googleIntegrationType,
  ldapIntegrationType,
} from '@/api/integrations/integrations'
import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import utc from 'dayjs-plugin-utc'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IntegrationTypeIcon from '@/assets/images/Integrations/FormStages/IntegrationType'
import GoogleWorkspaceIntegration from './GoogleWorkspaceIntegration'
import MicrosoftEntraIdIntegration from './MicrosoftEntraIdIntegration'
import OnPremiseIntegration from './OnPremiseIntegration'
import IntegrationPicker from './components/IntegrationPicker'
import { Step, Stepper } from './components/Stepper'

dayjs.extend(utc)

export type IntegrationCardType = {
  label: string
  value: string
  icon: string
  footnote?: string
  disabled?: boolean
}

interface IntegrationModalProps {
  title: string
  isOpen: boolean
  onClose: () => void
  integration: IntegrationType | undefined
  integrationTypes: IntegrationCardType[]
  existIntegrationsTypes: string[]
}
const IntegrationModal: FC<IntegrationModalProps> = ({
  title,
  isOpen,
  onClose,
  integration,
  integrationTypes,
  existIntegrationsTypes,
}) => {
  const { t } = useTranslation()
  const [type, setType] = useState(integration?.type || '')

  const resetTypeOnClose = () => {
    onClose()
  }

  useEffect(() => {
    setType(integration?.type || '')
  }, [integration])
  return (
    <Modal open={isOpen} onClose={resetTypeOnClose}>
      <ModalConent>
        <TitleBar>
          <IconButton aria-label="close" onClick={resetTypeOnClose}>
            <FontAwesomeIcon icon={faX as IconProp} color={theme.palette.cyan[500]} fontSize={14} />
          </IconButton>
          <Typography variant="h6" component="h2">
            {title}
          </Typography>
        </TitleBar>
        <Box sx={{ p: '16px', overflowY: 'auto', maxHeight: '90vh' }}>
          <Stepper>
            <Step label={t('settings.integrations.chooseIntegration')} icon={IntegrationTypeIcon} active={!integration}>
              <IntegrationPicker
                name="integration-type"
                value={type}
                integrationTypes={integrationTypes}
                existIntegrationsTypes={existIntegrationsTypes}
                onChange={(_, value) => setType(value)}
              />
            </Step>
            {type === 'ldap' && (
              <OnPremiseIntegration
                integration={integration?.type === 'ldap' ? (integration as ldapIntegrationType) : undefined}
                integrationTypes={integrationTypes}
                onClose={resetTypeOnClose}
              />
            )}
            {type === 'google-workspace' && (
              <GoogleWorkspaceIntegration
                integration={
                  integration?.type === 'google-workspace' ? (integration as googleIntegrationType) : undefined
                }
                integrationTypes={integrationTypes}
                onClose={resetTypeOnClose}
              />
            )}
            {type === 'ms-graph' && (
              <MicrosoftEntraIdIntegration
                integration={
                  integration?.type === 'ms-graph' ? (integration as MicrosoftEntraIdIntegrationType) : undefined
                }
                integrationTypes={integrationTypes}
                onClose={resetTypeOnClose}
              />
            )}
          </Stepper>
        </Box>
      </ModalConent>
    </Modal>
  )
}

export const FormSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}))

export const StyledButtonWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  transform: 'translate(0,-24px)',
  marginBottom: '-24px',
}))

const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -10%)',
  width: 640,
  backgroundColor: 'white',
  p: 4,
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '10px',
}))

const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: theme.palette.cyan[500],
  alignItems: 'center',
  padding: '10px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: 'normal',
  background: theme.palette.black,
  borderRadius: '10px 10px 0px 0px',
}))

export default IntegrationModal
