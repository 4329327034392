import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { useQueryClient } from '@tanstack/react-query'
import { ServerError } from '../types'
import { User } from './users'
import { NewUserParams } from './new-user'

type UserInput = Partial<NewUserParams> & { _id: string }
export type BulkEditUsersParams = UserInput[]

export async function editUsers(params: BulkEditUsersParams): Promise<User> {
  const url = `${API_URL}/users/bulk_edit`
  const { data } = await axios.post(url, params, { withCredentials: true })
  return data.data
}

export function useBulkEditUsers() {
  const queryCache = useQueryCache()
  const queryClient = useQueryClient()
  return useMutation<User, ServerError, BulkEditUsersParams>(editUsers, {
    throwOnError: true,
    onSettled: () => {
      queryCache.invalidateQueries('users')
      queryClient.invalidateQueries({ queryKey: ['members'] })
    },
  })
}
