import { API_URL } from '@/api/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryCache } from 'react-query'
import axios from 'axios'

export type ActivateNewAdminParams = {
  token: string
  password: string
}
export async function acceptInvitation(params: ActivateNewAdminParams) {
  const url = `${API_URL}/auth/accept-invitation/`
  const data = await axios.post(url, params, { withCredentials: true })

  return data
}

export function useAcceptInvitation() {
  const queryClient = useQueryClient()
  const queryCache = useQueryCache()
  return useMutation({
    mutationFn: acceptInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries()
      queryCache.invalidateQueries()
    },
  })
}
