import axios from 'axios'
import { useMutation } from 'react-query'

import { ServerError } from '../types'
import { API_URL } from '../constants'

type SignupParams = {
  full_name: string
  username: string
  password: string
  company_domains: string[]
  company_name: string
  phone_number: string
  recaptcha: string
  promo_code?: string
}

type SignupResponse = {
  used_promo_code: boolean
  applied_configurations: {
    [key: string]: string
  }
}

async function signup(signupData: SignupParams): Promise<SignupResponse> {
  const { data } = await axios.post<SignupResponse>(`${API_URL}/auth/signup/`, { ...signupData })
  return data
}

export function useSignup() {
  return useMutation<SignupResponse, ServerError, SignupParams>(signup, {
    throwOnError: true,
  })
}
