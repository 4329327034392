import { Box, Typography, styled } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import jsonData from '@/assets/pricingCalculatorSettings.json'
import { theme } from '@/theme/theme'
import CampaignsCountField from './components/CampaignsCountField'
import Header from './components/Header'
import MainAccordion from './components/MainAccordion'
import MainPlanCard from './components/MainPlanCard'
import MainTable from './components/MainTable'
import MembersCountFiels from './components/MembersCountFiels'
import PlanPicker from './components/PlanPicker'
import Summary from './components/Summary'

//TODO: all text to translations. (also for related components) (started on: pricingCalculator)

function PricingCalculator() {
  const [tailoredCost, setTailoredCost] = useState(jsonData.tailoredMemberCost)
  const [membersCount, setMembersCount] = useState<number | undefined>(1)
  const [campaignsCount, setCampaignsCount] = useState<number>(6)
  const [currentPlan, setCurrentPlan] = useState<string | undefined>()
  const [currentSubscriptionTime, setCrrentSubscriptionTime] = useState<number | undefined>()
  const [videosCount, setVideosCount] = useState<number>(0)
  const [videosCost, setVideosCost] = useState<number>(0)
  const liteCost = jsonData.liteMemberCost
  const tailoredCostRange = jsonData.tailoredMemberCostRange
  const humanfirewallCost = jsonData.humanFirewallMemberCost
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Header />
      <StyledContent>
        <Typography fontWeight={700} fontSize={46} padding={theme.spacing(4, 0, 2)}>
          {t('pricingCalculator.title')}
        </Typography>
        <Box display="flex" onClick={() => window.scrollBy({ top: 720, behavior: 'smooth' })}>
          <MainPlanCard
            title="LITE"
            limited
            subTitle="Min of 3 campaigns"
            price={liteCost.toString()}
            perCampaign
            preYear
            perMember
            description="Offers access to a customized number of campaigns selected by the organization."
          />
          <MainPlanCard
            title="TAILORED"
            subTitle="Based on additional features selected."
            price={tailoredCostRange}
            preYear
            perMember
            description="Flexible plan with unlimited campaigns and access to add additional features."
          />
          <MainPlanCard
            title="HUMAN FIREWALL"
            subTitle="25% Discount"
            price={humanfirewallCost.toString()}
            preYear
            perMember
            description="A premium plan for organizations focusing on cybersecurity, with unlimited campaigns and advanced features."
            colored
          />
        </Box>
        <MainAccordion title={t('pricingCalculator.table.title')} defaultExpanded>
          <Typography fontSize={20} fontWeight={400} width={930} textAlign="center" paddingBottom={4}>
            {t('pricingCalculator.table.subTitle')}
          </Typography>
          <MainTable
            data={jsonData.table}
            tailoredCost={tailoredCost}
            liteCost={liteCost}
            humanfirewallCost={humanfirewallCost}
            setTailoredCost={setTailoredCost}
            setVideosCount={setVideosCount}
            setVideosCost={setVideosCost}
          />
        </MainAccordion>
        <MainAccordion title={t('pricingCalculator.membersCount.title')}>
          <MembersCountFiels membersCount={membersCount} setMembersCount={setMembersCount}></MembersCountFiels>
        </MainAccordion>
        <MainAccordion title={t('pricingCalculator.campaignsCount.title')}>
          <CampaignsCountField campaignsCount={campaignsCount} setCampaignsCount={setCampaignsCount} />
        </MainAccordion>
        <MainAccordion title={t('pricingCalculator.compare.title')}>
          <PlanPicker
            tailoredMemberCost={tailoredCost}
            liteCost={liteCost}
            humanfirewallCost={humanfirewallCost}
            membersCount={membersCount || 0}
            campaignsCount={campaignsCount || 0}
            setCurrentPlan={setCurrentPlan}
            setCrrentSubscriptionTime={setCrrentSubscriptionTime}
            currentPlan={currentPlan}
            currentSubscriptionTime={currentSubscriptionTime}
          />
        </MainAccordion>
        <MainAccordion title={t('pricingCalculator.summary.title')}>
          <Summary
            currentPlan={currentPlan}
            liteCost={liteCost}
            tailoredCost={tailoredCost}
            humanfirewallCost={humanfirewallCost}
            membersCount={membersCount || 0}
            campaignsCount={campaignsCount}
            currentSubscriptionTime={currentSubscriptionTime}
            videosCount={videosCount}
            videosCost={videosCost}
          />
          <Typography fontSize={12} fontWeight={700} marginTop={theme.spacing(3)}>
            {t('pricingCalculator.disclaimer')}
          </Typography>
        </MainAccordion>
      </StyledContent>
    </Box>
  )
}

const StyledContent = styled(Box)(() => ({
  width: '98%',
  paddingBottom: theme.spacing(10),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export default PricingCalculator
