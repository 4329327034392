import { EducationModule } from '@/api/education-modules/modules'
import { theme } from '@/theme/theme'
import { Campaign } from '@/types/campaigns'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faBlockQuestion,
  faBullhorn,
  faHexagon,
  faSwords,
  faWavePulse,
  faClapperboardPlay,
  faGauge,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider, Typography, Tooltip, styled } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

type CampainDetailsProps = {
  campaignData: Campaign
  membersData: Campaign
  asset?: EducationModule
  isSimulation?: boolean
  phishingSimulations?: PhishingSimulation[]
  vectors?: string[]
}
const CampaignDetails: FC<CampainDetailsProps> = ({
  campaignData,
  membersData,
  asset,
  isSimulation = false,
  phishingSimulations = [],
  vectors = [],
}: CampainDetailsProps) => {
  const { t } = useTranslation()

  const vectorText = vectors.map((vector) => t('library.' + vector)).join(', ')
  const hasMultipleVectors = vectors.length > 1
  const difficulties = [...new Set(phishingSimulations?.flatMap((sim) => sim.difficulties) || [])].join(', ')

  const assetTypeText = () => {
    if (phishingSimulations?.length > 1) {
      return t('campaignDashboard.package', { simulationCount: phishingSimulations.length })
    }
    return t('campaignDashboard.' + campaignData.asset_type)
  }

  const campaignName = campaignData.name.length > 12 ? campaignData.name.substring(0, 12) + '...' : campaignData.name

  return (
    <div>
      <DetailBox>
        <FontAwesomeIcon icon={faBullhorn as IconProp} fontSize={18} />
        <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.campaignName') + ': '}</KeyTypography>
        <Tooltip title={campaignData.name} placement="top" disableHoverListener={campaignData.name.length < 12}>
          <Typography color={'inherit'}>{campaignName}</Typography>
        </Tooltip>
      </DetailBox>
      <StyledDivider orientation="horizontal" />
      <DetailBox>
        <FontAwesomeIcon icon={faHexagon as IconProp} fontSize={18} />
        <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.type') + ': '}</KeyTypography>
        <Typography color={'inherit'}>{assetTypeText()}</Typography>
      </DetailBox>

      {isSimulation ? (
        <>
          <StyledDivider orientation="horizontal" />
          <DetailBox>
            <FontAwesomeIcon icon={faSwords as IconProp} fontSize={18} />
            <KeyTypography color={'inherit'}>{t('campaignDashboard.attackVector') + ': '}</KeyTypography>
            <Tooltip title={vectorText} placement="top" disableHoverListener={!hasMultipleVectors}>
              <Typography color={'inherit'}>
                {hasMultipleVectors ? t('campaignDashboard.mixedVectors') : vectorText}
              </Typography>
            </Tooltip>
          </DetailBox>
          <StyledDivider orientation="horizontal" />
          <DetailBox>
            <FontAwesomeIcon icon={faGauge as IconProp} fontSize={18} />
            <KeyTypography color={'inherit'}>{t('campaignDashboard.difficulty') + ': '}</KeyTypography>
            <Typography color={'inherit'}>{difficulties}</Typography>
          </DetailBox>
        </>
      ) : (
        <>
          <StyledDivider orientation="horizontal" />
          <DetailBox>
            <FontAwesomeIcon icon={faClapperboardPlay as IconProp} fontSize={18} />
            <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.video') + ': '}</KeyTypography>
            <Typography color={'inherit'}>
              <NavLink style={{ color: '#003CD6' }} to={'/content-library/videos/' + asset?._id}>
                {asset?.title}
              </NavLink>
            </Typography>
          </DetailBox>
        </>
      )}
      {!!asset?.quizzes?.length && (
        <>
          <StyledDivider orientation="horizontal" />
          <DetailBox>
            <FontAwesomeIcon icon={faBlockQuestion as IconProp} fontSize={18} />
            <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.quiz') + ': '}</KeyTypography>
            <Typography color={'inherit'}>
              {(asset?.quizzes ? asset.quizzes[0].questions.length : 0) +
                ' ' +
                t('trainingCampaignDashboard.questions')}
            </Typography>
          </DetailBox>
        </>
      )}
      <StyledDivider orientation="horizontal" />
      <DetailBox>
        <FontAwesomeIcon icon={faUsers as IconProp} fontSize={18} />
        <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.totalMembers') + ': '}</KeyTypography>
        <Typography color={'inherit'}>{membersData.total}</Typography>
      </DetailBox>
      <StyledDivider orientation="horizontal" />
      <DetailBox>
        <FontAwesomeIcon icon={faWavePulse as IconProp} fontSize={18} />
        <KeyTypography color={'inherit'}>{t('trainingCampaignDashboard.status') + ': '}</KeyTypography>
        <Typography color={'inherit'}>{t('trainingCampaignDashboard.' + campaignData.status)}</Typography>
      </DetailBox>
    </div>
  )
}

const StyledDivider = styled(Divider)(() => ({
  margin: '0px 15px',
  borderBottomWidth: '3px',
  borderColor: '#E0EDEF',
}))

const KeyTypography = styled(Typography)(() => ({
  fontWeight: theme.typography.fontWeightBold,
}))

const DetailBox = styled(Box)(() => ({
  display: 'flex',
  padding: '12px 26px',
  gap: '12px',
  alignItems: 'center',
}))

export default CampaignDetails
