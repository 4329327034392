import { Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAdminAnalytics } from '@/api/admin-analytics/admin-analytics'
import { useClientDetails } from '@/api/client/client'
import { useDashboardAnalytics } from '@/api/dashboard-analytics'
import AwarenessScore from '@/common/components/AwarenessScore/AwarenessScore'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '@/common/components/NoResultsContainer/NoResultsContainer'
import DepartmentScores from './DepartmentScores'
import PendingAlerts from './PendingAlerts'
import UpcomingCampaigns from './UpcomingCampaigns'

function Dashboard() {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data, isLoading } = useDashboardAnalytics()
  const { data: clientDetails, isLoading: isLoadingClientDetails } = useClientDetails()
  const { data: adminAnalyticsData, isLoading: isLoadingAdminAnalytics } = useAdminAnalytics()
  const theme = useTheme()
  const isLargeSizeScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const classes = useStyles({ isLargeSizeScreen })

  useEffect(() => {
    if (clientDetails) {
      if (!clientDetails.onboarding) {
        navigateTo('/onboarding')
      } else if (
        clientDetails.baseline &&
        moment.unix(clientDetails.baseline_execution).add(7, 'days').isAfter(moment())
      ) {
        navigateTo('/baseline')
      }
    }
  }, [clientDetails, navigateTo])

  return (
    <div className={classes.root}>
      {isLoading || isLoadingAdminAnalytics || isLoadingClientDetails ? (
        <LoadingContainer />
      ) : data && adminAnalyticsData && clientDetails ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.flexContainer}>
              <Typography gutterBottom fontWeight={theme.typography.fontWeightSemiBold}>
                {t('adminDashboard.dashboardTitle')}
              </Typography>
              <InfoTooltip
                title={t('adminDashboard.dashboardTooltip', {
                  clientVelocity: clientDetails.velocity,
                })}
              />
            </div>
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Paper className={classes.chartContainer}>
              <div className={classes.flexContainer}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {t('adminDashboard.activeCampaigns')}
                </Typography>
                <InfoTooltip title={t('adminDashboard.activeCampaignsTooltip')} />
              </div>
              <Typography variant={'h2'} className={classes.campaignCount}>
                {adminAnalyticsData.active_campaigns}
              </Typography>
            </Paper>
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Paper className={classes.chartContainer}>
              <AwarenessScore
                score={data?.awareness_score}
                monthlyScores={data?.awareness_last_30_days}
                loading={isLoading}
              />
            </Paper>
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Paper className={classes.chartContainer}>
              <PendingAlerts reports={adminAnalyticsData.pending_reports.items} />
            </Paper>
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Paper className={classes.chartContainer}>
              <div className={classes.flexContainer}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {t('adminDashboard.departmentScores')}
                </Typography>
                <InfoTooltip title={t('adminDashboard.departmentScoresTooltip')} />
              </div>
              <DepartmentScores />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.tableContainer}>
              <div className={classes.flexContainer}>
                <Typography gutterBottom variant={'subtitle2'}>
                  {t('adminDashboard.upcomingCampaigns')}
                </Typography>
                <InfoTooltip title={t('adminDashboard.upcomingCampaignsTooltip')} />
              </div>
              <UpcomingCampaigns campaigns={adminAnalyticsData?.future_campaigns} />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <NoResultsContainer text={'dashboard.noResults'} />
      )}
    </div>
  )
}

export default Dashboard

const useStyles = makeStyles<Theme, { isLargeSizeScreen: boolean }>((theme) =>
  createStyles({
    root: { height: '100%', width: '100%', padding: theme.spacing(2, 2) },
    chartContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      padding: theme.spacing(2, 2),
      height: 280,
      boxShadow: '0px 10px 8px rgba(0, 0, 0, 0.25)',
      overflow: 'hidden',
    },
    simulatorContainer: {
      padding: theme.spacing(2, 3),
      height: ({ isLargeSizeScreen }) => (isLargeSizeScreen ? 480 : 320),
      width: '100%',
      position: 'relative',
      boxShadow: '0px 10px 8px rgba(0, 0, 0, 0.25)',
    },
    scoreMeterContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      color: theme.palette.text.secondary,
      textTransform: 'capitalize',
    },
    flexContainer: {
      display: 'flex',
    },
    flexSpaceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    campaignCount: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      textAlign: 'center',
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableContainer: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      height: 'fit-content',
      boxShadow: '0px 10px 8px rgba(0, 0, 0, 0.25)',
    },
  })
)
