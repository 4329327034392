import Checkbox from '@/common/components/Inputs/Checkbox'
import TextField from '@/common/components/Inputs/TextField'
import { Box, Button, Typography, styled } from '@mui/material'
import Modal from '@mui/material/Modal'
import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useToast from '../../hooks/useToast'

import { DOMAIN_REGEX } from '@/api/constants'
import { useOrganizationCreate } from '@/api/organizations/create'
import { getErrorMessage } from '@/api/utils/get-error'
import { OrganizationAccountType, OrganizationRequest } from '@/types/organizations'
//@ts-ignore
import * as Yup from 'yup'
import InvitePopup from './InvitePopup'
import { ButtonsWrapper, CheckboxWrapper, FieldsWrapper, ModalConent, TitleBar } from './ModalStyledComponents'
import { ModalProps } from './TreeChart'

const AddModal: FC<ModalProps> = ({ open, setOpen, organization, focusNode }) => {
  const { t } = useTranslation()
  const [afterClick, setAfterClick] = useState(false)
  const [backFromInvite, setBackFromInvite] = useState(false)
  const [initialValues, setInitialValues] = useState({
    email: '',
    adminName: '',
    organizationName: '',
    domain: '',
    allowAdd: false,
  })
  const [organizationPayload, setOrganizationPayload] = useState<OrganizationRequest>({
    parent_id: organization.id,
    name: '',
    type: OrganizationAccountType.client,
  })

  const { successToast, errorToast } = useToast()
  const { mutateAsync: createOrganization } = useOrganizationCreate()
  const handleClose = () => {
    setOpen(false)
  }

  const resetCreate = () => {
    setBackFromInvite(false)
  }

  const handleAdd = async (values: {
    adminName: string
    email: string
    organizationName: string
    domain: string
    allowAdd: boolean
  }) => {
    const payload = {
      parent_id: organization.id,
      name: values.organizationName,
      admin_email: values.email,
      type: values.allowAdd ? OrganizationAccountType.partner : OrganizationAccountType.client,
      domain: values.domain,
      admin_name: values.adminName,
    }

    try {
      if (values.email) {
        setInitialValues(values)
        setOrganizationPayload(payload)
        setAfterClick(true)
      } else {
        const newOrganization = await createOrganization(payload)
        handleClose()
        successToast(t('accountCenter.addModal.addSuccessful'))
        setTimeout(() => {
          focusNode && focusNode(newOrganization, false)
        }, 100)
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('accountCenter.addModal.addFailed'))
    }
  }

  const validationSchema = Yup.object().shape({
    adminName: Yup.string().test(
      'adminName-required-if-email',
      t('accountCenter.addModal.adminNameRequired'),
      function (this: Yup.TestContext, value: string | undefined) {
        const { email } = this.parent
        if (email && email.length > 0) {
          return !!value
        }
        return true
      }
    ),
    email: Yup.string()
      .email(t('accountCenter.addModal.emailInvalid'))
      .test(
        'email-required-if-adminName',
        t('accountCenter.addModal.emailRequired'),
        function (this: Yup.TestContext, value: string | undefined) {
          const { adminName } = this.parent
          if (adminName && adminName.length > 0) {
            return !!value
          }
          return true
        }
      ),
    organizationName: Yup.string()
      .min(2, t('accountCenter.addModal.tooShort'))
      .max(50, t('accountCenter.addModal.tooLong'))
      .required(t('accountCenter.addModal.organizationNameRequired')),
    domain: Yup.string()
      .matches(DOMAIN_REGEX, t('accountCenter.addModal.domainWarning'))
      .required(t('accountCenter.addModal.domainWarning')),
    allowAdd: Yup.boolean(),
  })

  return (
    <>
      {!afterClick ? (
        <Modal open={open} onClose={handleClose}>
          <ModalConent>
            <TitleBar>
              <Typography variant="h6" component="h2" fontFamily="Montserrat">
                {t('accountCenter.addModal.title')}
              </Typography>
            </TitleBar>
            <Box sx={{ p: '16px', overflowY: 'auto', maxHeight: '90vh' }}>
              <Formik onSubmit={handleAdd} initialValues={initialValues} validationSchema={validationSchema}>
                {({ isSubmitting, values, isValid, dirty, errors }) => (
                  <Form>
                    <FieldsWrapper>
                      <StyledFieldBox>
                        <TextField
                          onChange={resetCreate}
                          label={values.adminName && t('accountCenter.addModal.name')}
                          name="adminName"
                          placeholder={t('accountCenter.addModal.name')}
                        />
                        {!errors.adminName && (
                          <Typography fontSize={10} style={{ padding: '4px 14px' }}>
                            {t('accountCenter.addModal.notReqired')}
                          </Typography>
                        )}
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <TextField
                          onChange={resetCreate}
                          label={values.email && t('accountCenter.addModal.email')}
                          name="email"
                          placeholder={t('accountCenter.addModal.email')}
                        />
                        {!errors.email && (
                          <Typography fontSize={10} style={{ padding: '4px 14px' }}>
                            {t('accountCenter.addModal.inviteWillBeSent')}
                          </Typography>
                        )}
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <TextField
                          onChange={resetCreate}
                          label={values.organizationName && t('accountCenter.addModal.organizationName')}
                          name="organizationName"
                          placeholder={t('accountCenter.addModal.organizationName')}
                        />
                      </StyledFieldBox>
                      <StyledFieldBox>
                        <TextField
                          onChange={resetCreate}
                          label={values.domain && t('accountCenter.addModal.domain')}
                          name="domain"
                          placeholder={t('accountCenter.addModal.domain')}
                        />
                      </StyledFieldBox>
                      <CheckboxWrapper sx={{ height: '50px', fontSize: '10px' }}>
                        <Checkbox
                          onChange={resetCreate}
                          name="allowAdd"
                          label={t('accountCenter.addModal.allowAdd')}
                          checked={values.allowAdd}
                        />
                      </CheckboxWrapper>
                    </FieldsWrapper>

                    <ButtonsWrapper>
                      <Button variant="outlined" onClick={handleClose}>
                        {t('accountCenter.addModal.cancel')}
                      </Button>
                      <Button
                        type="submit"
                        disabled={!backFromInvite && (!dirty || !isValid || isSubmitting)}
                        variant="contained">
                        {t('accountCenter.addModal.submit')}
                      </Button>
                    </ButtonsWrapper>
                  </Form>
                )}
              </Formik>
            </Box>
          </ModalConent>
        </Modal>
      ) : (
        <InvitePopup
          open={open}
          setOpen={() => setAfterClick(false)}
          onClose={() => {
            setBackFromInvite(true)
          }}
          onCreate={handleClose}
          organizationPayload={organizationPayload}
          focusNode={focusNode}
        />
      )}
    </>
  )
}

const StyledFieldBox = styled(Box)(() => ({
  height: '50px',
}))

export default AddModal
