import React from "react";
import { ColumnOptions } from "./MergeUsersSelector";
import { DuplicateUserErrorData } from "../../../../api/users/new-user";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type MergeDuplicatesRadioLabelType = {
  data: DuplicateUserErrorData;
  column: ColumnOptions;
};
export default function MergeDuplicatesRadioLabel({
  data,
  column,
}: MergeDuplicatesRadioLabelType) {
  const { t } = useTranslation();
  const user =
    column === ColumnOptions.CSV ? data.user_input : data.existing_user;
  return (
    <div>
      <Typography variant="body1">
        {user.first_name} {user.last_name}
      </Typography>
      <Typography
        variant="body2"
        style={
          data.existing_user.username === data.user_input.username
            ? { fontWeight: "bold", color: "red" }
            : {}
        }
      >
        {t("bulkUserUpload.errors.duplicatesInDbAlert.email")}: {user.username}
      </Typography>
      <Typography
        variant="body2"
        style={
          data.user_input.phone_number &&
          data.existing_user.phone_number === data.user_input.phone_number
            ? { fontWeight: "bold", color: "red" }
            : {}
        }
      >
        {t("bulkUserUpload.errors.duplicatesInDbAlert.phone")}:{" "}
        {user.phone_number}
      </Typography>
    </div>
  );
}
