export enum Status {
  enabled = 'enabled',
  disabled = 'disabled',
  pending = 'pending',
}

export type Admin = {
  _id: string
  username: string
  full_name?: string
  locked?: boolean
  status?: Status
  invited_by?: string
  joined_date?: number
  last_active?: number
}
