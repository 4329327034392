import { Box, Typography, InputAdornment, Switch, Button } from '@mui/material'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
// @ts-ignore
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { ConfigurationsProps } from '.'
import { useChangePassword } from '@/api/profile/change-password'
import useToast from '@/common/hooks/useToast'
import { updateClient } from '@/api/client/update-client'
import { FieldWrapper, StyledTextField, Title } from './ConfigurationsStyledComponents'

const ResetPassword: FC<ConfigurationsProps> = ({ organiztion }) => {
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
  const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] = useState(false)
  const [changePassword, { isLoading: isLoadingPassword }] = useChangePassword()
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    mfaEnabled: organiztion?.mfa_enabled || false,
  }
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().min(8).required(),
    currentPassword: Yup.string().min(8).required(),
    confirmNewPassword: Yup.string().min(8).required(),
  })
  async function handleChangePassword(values: { currentPassword: string; newPassword: string; mfaEnabled: boolean }) {
    const { newPassword: new_password, currentPassword: current_password, mfaEnabled } = values
    if (new_password && current_password) {
      try {
        await changePassword({ new_password, current_password })
        await updateClient({ mfa_enabled: mfaEnabled })
        successToast(t('settings.configurationTab.resetPassword.passwordChanged'))
      } catch (e) {
        errorToast(t('settings.configurationTab.resetPassword.failedToChangePassword'))
      }
    }
  }

  return (
    <Box>
      <Title>{t('settings.configurationTab.resetPassword.resetPassword')}</Title>
      <Formik onSubmit={handleChangePassword} initialValues={initialValues} validationSchema={validationSchema}>
        {({ setFieldValue, isSubmitting, values, touched, setTouched, dirty, errors, isValid }) => (
          <Form>
            <FieldWrapper>
              <StyledTextField
                size="small"
                required
                type={isCurrentPasswordVisible ? 'text' : 'password'}
                label={t('settings.configurationTab.resetPassword.currentPassword')}
                name="currentPassword"
                placeholder="********"
                onChange={(value) => {
                  setFieldValue('currentPassword', value.target.value)
                  setTouched({ ...touched, ['currentPassword']: true }, true)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          setIsCurrentPasswordVisible((e) => !e)
                        }}
                        icon={(isCurrentPasswordVisible ? faEyeSlash : faEye) as IconProp}
                      />
                    </InputAdornment>
                  ),
                }}
                error={touched.currentPassword && !!errors.currentPassword}
              />
            </FieldWrapper>
            {values.newPassword && values.confirmNewPassword && values.newPassword !== values.confirmNewPassword && (
              <FieldWrapper>
                <FontAwesomeIcon
                  onClick={() => {
                    setIsNewPasswordVisible((e) => !e)
                  }}
                  icon={faTriangleExclamation as IconProp}
                  color="red"
                  fontSize={25}
                  style={{ marginRight: '10px' }}
                />
                <Typography>
                  <Trans
                    i18nKey={'settings.configurationTab.resetPassword.passwordDontMatch'}
                    components={{
                      1: <b />,
                    }}
                  />
                </Typography>
              </FieldWrapper>
            )}
            <FieldWrapper>
              <StyledTextField
                size="small"
                required
                type={isNewPasswordVisible ? 'text' : 'password'}
                label={t('settings.configurationTab.resetPassword.newPassword')}
                name="newPassword"
                placeholder="********"
                onChange={(value) => {
                  setFieldValue('newPassword', value.target.value)
                  setTouched({ ...touched, ['newPassword']: true }, true)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          setIsNewPasswordVisible((e) => !e)
                        }}
                        icon={(isNewPasswordVisible ? faEyeSlash : faEye) as IconProp}
                      />
                    </InputAdornment>
                  ),
                }}
                error={touched.newPassword && !!errors.newPassword}
              />
            </FieldWrapper>

            <FieldWrapper>
              <StyledTextField
                size="small"
                required
                type={isConfirmNewPasswordVisible ? 'text' : 'password'}
                label={t('settings.configurationTab.resetPassword.confirmNewPassword')}
                name="confirmNewPassword"
                placeholder="********"
                onChange={(value) => {
                  setFieldValue('confirmNewPassword', value.target.value)
                  setTouched({ ...touched, ['confirmNewPassword']: true }, true)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          setIsConfirmNewPasswordVisible((e) => !e)
                        }}
                        icon={(isConfirmNewPasswordVisible ? faEyeSlash : faEye) as IconProp}
                      />
                    </InputAdornment>
                  ),
                }}
                error={touched.confirmNewPassword && !!errors.confirmNewPassword}
              />
            </FieldWrapper>
            <FieldWrapper>
              <Switch
                checked={values.mfaEnabled}
                onChange={() => {
                  setFieldValue('mfaEnabled', !values.mfaEnabled)
                  setTouched({ ...touched, ['mfaEnabled']: true }, true)
                }}
              />
              <Typography>{t('settings.configurationTab.resetPassword.mfaEnabled')}</Typography>
            </FieldWrapper>
            <FieldWrapper>
              <Button
                sx={{ width: '100%' }}
                type="submit"
                disabled={!dirty || !isValid || isSubmitting || isLoadingPassword}
                variant="contained"
              >
                {t('settings.configurationTab.resetPassword.changePassword')}
              </Button>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default ResetPassword
