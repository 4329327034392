import React, { useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CSVReader from 'react-csv-reader'
import LinearProgress from '@mui/material/LinearProgress'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SimpleTable from '../Tables/SimpleTable'
import { ColumnInterface } from 'react-table'

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  encoding: 'utf-8',
  transformHeader: (header) => (header ? header.toLowerCase().replace(/\W/g, '_') : ''),
}

type UploadDialogProps = {
  open: boolean
  handleClose: () => void
  handleClear: () => void
  handleUploadFile: (data: any, fileInfo: any) => void
  fileName?: string
  onSubmit: () => void
  errorMessages: string[]
  highlightedRows: number[]
  isLoading: boolean
  numberOfUploadedUsers: number
  rawFileData?: { [key: string]: string }[]
  updateRawFileData?: React.Dispatch<React.SetStateAction<any[]>>
}

export default function UploadDialog({
  open,
  handleClose,
  handleUploadFile,
  fileName,
  onSubmit,
  errorMessages,
  highlightedRows,
  isLoading,
  handleClear,
  numberOfUploadedUsers,
  rawFileData = [],
  updateRawFileData = () => {},
}: UploadDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [id, setId] = useState(Math.random().toString())

  function handleOpenFileExplorer() {
    const uploadInput = window.document.getElementById('csv-upload-input')
    if (uploadInput) {
      uploadInput.click()
    }
  }

  function onFileLoaded(data, fileInfo) {
    setId(Math.random().toString())
    handleUploadFile(data, fileInfo)
  }

  async function handleSubmit() {
    await onSubmit()
    handleClose()
  }

  const columns: ColumnInterface[] = React.useMemo(
    () => [
      {
        Header: t('bulkUserUpload.exampleTable.number'),
        accessor: null,
        Cell: (data) => {
          return +data.row.id + 1
        },
      },
      {
        Header: t('bulkUserUpload.exampleTable.firstName'),
        accessor: 'first_name',
      },
      {
        Header: t('bulkUserUpload.exampleTable.lastName'),
        accessor: 'last_name',
      },
      {
        Header: t('bulkUserUpload.exampleTable.username'),
        accessor: 'email',
      },
      {
        Header: t('bulkUserUpload.exampleTable.phone'),
        accessor: 'phone_number',
      },
      {
        Header: t('bulkUserUpload.exampleTable.office'),
        accessor: 'office',
        disableSortBy: true,
      },
      {
        Header: t('bulkUserUpload.exampleTable.groups'),
        accessor: 'groups',
        disableSortBy: true,
      },
    ],
    [t]
  )

  return (
    <>
      <Dialog
        disableEscapeKeyDown={isLoading}
        scroll={'body'}
        classes={{ paperFullWidth: classes.root }}
        fullWidth
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="user-dialog-title"
      >
        <DialogTitle id="user-dialog-title">{t('bulkUserUpload.bulkUpdateUsers')}</DialogTitle>
        <DialogContent>
          <Stepper nonLinear activeStep={2} orientation="vertical" classes={{ root: classes.contentContainer }}>
            <Step active>
              <StepLabel>{t('bulkUserUpload.downloadCsvFile')}</StepLabel>
              <StepContent>
                <div className={classes.stepContent}>
                  <Button
                    variant={'contained'}
                    color={'inherit'}
                    className={classes.button}
                    href={'https://content.cywareness.io/add_users_template.csv'}
                  >
                    {t('bulkUserUpload.downloadTemplate')}{' '}
                  </Button>
                </div>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>{t('bulkUserUpload.editCSV')}</StepLabel>
              <StepContent>
                <Typography gutterBottom variant={'body2'} color={'textSecondary'}>
                  {t('bulkUserUpload.editCSVInstructions')}
                </Typography>
                <Typography gutterBottom variant={'body2'} color={'textSecondary'}>
                  {t('bulkUserUpload.officeFieldInstructions')}
                </Typography>
                <Typography gutterBottom variant={'body2'} color={'textSecondary'}>
                  {t('bulkUserUpload.groupsFieldInstructions')}
                </Typography>
                <Typography gutterBottom variant={'body2'} color={'textSecondary'}>
                  {t('bulkUserUpload.phoneNumberInstructions')}
                </Typography>
                <div className={classes.stepContent}>
                  <table className={classes.exampleTable}>
                    <thead>
                      <tr className={classes.tableHeader}>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                        <th>D</th>
                        <th>E</th>
                        <th>F</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className={classes.firstRow}>
                        <td>{t('bulkUserUpload.exampleTable.firstName')}</td>
                        <td>{t('bulkUserUpload.exampleTable.lastName')}</td>
                        <td>{t('bulkUserUpload.exampleTable.username')}</td>
                        <td>{t('bulkUserUpload.exampleTable.phone')}</td>
                        <td>{t('bulkUserUpload.exampleTable.office')}</td>
                        <td>{t('bulkUserUpload.exampleTable.groups')}</td>
                      </tr>
                      <tr>
                        <td>{t('bulkUserUpload.exampleTable.firstNameExample')}</td>
                        <td>{t('bulkUserUpload.exampleTable.lastNameExample')}</td>
                        <td>{t('bulkUserUpload.exampleTable.usernameExample')}</td>
                        <td>{t('bulkUserUpload.exampleTable.phoneExample')}</td>
                        <td>{t('bulkUserUpload.exampleTable.officeExample')}</td>
                        <td>{t('bulkUserUpload.exampleTable.groupsExample')}</td>
                      </tr>
                    </tbody>
                  </table>{' '}
                </div>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>{t('bulkUserUpload.uploadCsv')}</StepLabel>
              <StepContent>
                <div className={classes.flexContainer}>
                  <Button onClick={handleOpenFileExplorer} variant={'contained'} className={classes.button}>
                    {t('bulkUserUpload.attachCSV')}{' '}
                  </Button>
                  <InputLabel>{fileName ? fileName : t('bulkUserUpload.noFile')}</InputLabel>
                  {fileName ? (
                    <IconButton size={'small'} onClick={handleClear}>
                      <FontAwesomeIcon icon={faX as IconProp} fontSize={14} />
                    </IconButton>
                  ) : null}
                </div>
              </StepContent>
            </Step>
          </Stepper>
          {errorMessages.length > 0 && (
            <div className={classes.errorsContainer}>
              <Typography gutterBottom variant={'subtitle2'}>
                {t(numberOfUploadedUsers > 1 ? 'bulkUserUpload.errors.badCSV' : 'bulkUserUpload.errors.noUsers')}
              </Typography>
              <div className={classes.errorsList}>
                {errorMessages.map((value) => (
                  <Typography key={value} className={classes.errorMessage} variant={'subtitle2'}>
                    {value}
                  </Typography>
                ))}
              </div>
            </div>
          )}
          <div className={classes.editableTableContainer}>
            <SimpleTable
              highlightRows={{ variant: 'error', rows: highlightedRows || [] }}
              isEditable={false}
              enableCheckbox={false}
              columns={columns}
              data={rawFileData}
              setData={updateRawFileData}
              noResultsTextId={'bulkUserUpload.exampleTable.noResults'}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('bulkUserUpload.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained" disabled={!fileName || numberOfUploadedUsers < 1}>
            {!isLoading ? (
              numberOfUploadedUsers > 0 ? (
                t('bulkUserUpload.uploadUsers', {
                  count: numberOfUploadedUsers,
                })
              ) : (
                t('bulkUserUpload.upload')
              )
            ) : (
              <CircularProgress color={'primary'} size={20} />
            )}
          </Button>
        </DialogActions>
        {isLoading && (
          <div className={classes.blocker}>
            <LinearProgress />
          </div>
        )}
      </Dialog>
      <CSVReader
        key={id}
        cssClass={classes.fileInput}
        onFileLoaded={onFileLoaded}
        onError={(error) => console.log(error)}
        parserOptions={papaparseOptions}
        inputId="csv-upload-input"
      />
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 750,
      position: 'relative',
    },
    contentContainer: {
      borderRadius: theme.shape.borderRadius,
    },
    stepContent: {
      padding: theme.spacing(0),
    },
    button: {
      background: theme.palette.common.white,
      color: theme.palette.blueGray[900],
      boxShadow: theme.shadows[4],
      marginRight: theme.spacing(2),
      '&:hover': {
        background: theme.palette.common.white,
      },
    },
    exampleTable: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      cellPadding: 8,
      fontSize: 12,
    },
    tableHeader: {
      background: theme.palette.background.default,
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
    },
    firstRow: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 12,
    },
    fileInput: {
      display: 'none',
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    errorsContainer: {
      margin: theme.spacing(2, 3, 0),
    },
    editableTableContainer: {
      marginTop: theme.spacing(2),
    },
    errorsList: {
      border: `1px solid ${theme.palette.error.light}`,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.grey['200'],
      padding: theme.spacing(2),
    },
    errorMessage: {
      color: theme.palette.error.main,
    },
    blocker: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgba(0,0,0,0.45)',
    },
  })
)
