import { useState, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { DuplicateUserErrorData, NewUserParams } from '@/api/users/new-user'
import { Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MergeDuplicatesTableRow from './MergeDuplicatesTableRow'

type MergeUsersSelectorProps = {
  users: DuplicateUserErrorData[]
  handleMergeUsers: (users: DuplicateUserErrorData[]) => void
}

export enum ColumnOptions {
  CSV = 'csv',
  DB = 'db',
}

export default function MergeUsersSelector({ users, handleMergeUsers }: MergeUsersSelectorProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [usersEditStatus, setUsersEditStatus] = useState<{
    [uniqueId: string]: ColumnOptions
  }>({})

  function createUniqueId(user: NewUserParams) {
    return `${user.username}_${user.phone_number}`
  }

  const usersDataByUniqueId: {
    [uniqueId: string]: DuplicateUserErrorData
  } = useMemo(() => {
    const usersById = {}
    users.forEach((user) => {
      const uniqueId = createUniqueId(user.user_input)
      usersById[uniqueId] = user
    })
    return usersById
  }, [users])

  const usersToEdit = useMemo(() => {
    const filteredUsers = Object.entries(usersDataByUniqueId).filter(
      ([uniqueId]) => usersEditStatus[uniqueId] === ColumnOptions.CSV
    )
    return filteredUsers.map(([uniqueId, data]) => {
      return data
    })
  }, [usersDataByUniqueId, usersEditStatus])

  function selectAll(from: ColumnOptions) {
    setUsersEditStatus(
      Object.keys(usersDataByUniqueId).reduce((acc, user) => {
        return { ...acc, [user]: from }
      }, {})
    )
  }

  async function mergeSelectedUsers() {
    await handleMergeUsers(usersToEdit)
  }

  function handleChange(userUniqueId: string) {
    return function (value: ColumnOptions) {
      setUsersEditStatus({
        ...usersEditStatus,
        [userUniqueId]: value,
      })
    }
  }

  const topRowRadioValue =
    Object.values(usersEditStatus).filter((value) => value === ColumnOptions.CSV).length === users.length
      ? ColumnOptions.CSV
      : Object.values(usersEditStatus).filter((value) => value === ColumnOptions.DB).length === users.length
      ? ColumnOptions.DB
      : ''

  return (
    <div className={classes.root}>
      <Typography variant="h6">{t('bulkUserUpload.errors.duplicatesInDbAlert.selectUsersForMerge')}</Typography>
      <Typography variant="subtitle2">{t('bulkUserUpload.errors.duplicatesInDbAlert.mergeWarning')}</Typography>
      <div className={classes.container}>
        <RadioGroup value={topRowRadioValue} onChange={(e) => selectAll(e?.target?.value as ColumnOptions)} row>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                value={ColumnOptions.CSV}
                control={<Radio />}
                label={t('bulkUserUpload.errors.duplicatesInDbAlert.usersFromCsv')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value={ColumnOptions.DB}
                control={<Radio />}
                label={t('bulkUserUpload.errors.duplicatesInDbAlert.usersFromDb')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
        <Divider />
        {users.map((user) => {
          const uniqueId = createUniqueId(user.user_input)
          return (
            <MergeDuplicatesTableRow
              key={uniqueId}
              user={user}
              value={usersEditStatus[uniqueId]}
              onChange={handleChange(uniqueId)}
            />
          )
        })}
      </div>
      <Button
        disabled={Object.keys(usersEditStatus).length !== users.length}
        onClick={mergeSelectedUsers}
        variant="contained"
      >
        {t('bulkUserUpload.errors.duplicatesInDbAlert.merge')}
      </Button>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { padding: theme.spacing(0, 2) },
    container: { marginBottom: theme.spacing(2) },
  })
)
