import React from "react";
import { ColumnOptions } from "./MergeUsersSelector";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { DuplicateUserErrorData } from "../../../../api/users/new-user";
import MergeDuplicatesRadioLabel from "./MergeDuplicatesRadioLabel";

type MergeDuplicatesTableRowProps = {
  user: DuplicateUserErrorData;
  value: ColumnOptions;
  onChange: (value: ColumnOptions) => void;
};
export default function MergeDuplicatesTableRow({
  user,
  value,
  onChange,
}: MergeDuplicatesTableRowProps) {
  return (
    <RadioGroup
      key={value}
      value={value}
      onChange={(e) => {
        onChange(e?.target?.value as ColumnOptions);
      }}
      row
    >
      <Grid container>
        <Grid item xs={6}>
          <FormControlLabel
            value={ColumnOptions.CSV}
            control={<Radio />}
            label={
              <MergeDuplicatesRadioLabel
                data={user}
                column={ColumnOptions.CSV}
              />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={ColumnOptions.DB}
            control={<Radio />}
            label={
              <MergeDuplicatesRadioLabel
                data={user}
                column={ColumnOptions.DB}
              />
            }
          />
        </Grid>
      </Grid>
    </RadioGroup>
  );
}
