import { RowDataItem, SortOrder } from '@/types/common'
import { Checkbox, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { ChangeEvent, FC, MouseEvent, ReactElement, ReactNode, useMemo, useState } from 'react'
import CustomTableFooter from './TableFooter'
import CustomTableHead from './TableHead'
import TableNoResults from './TableNoResults'
import TableSceletonLoader from './TableSceletonLoader'

export type TalbeColumn = {
  id: string
  label: string | ReactElement
  sortable?: boolean
  format?: (value: string) => string
  component?: (row: any) => ReactNode
}

type CustomTablePros = {
  loading: boolean
  error: boolean
  data: RowDataItem[]
  total: number
  columns: TalbeColumn[]
  rowClick?: (row: RowDataItem) => void
  selectable?: boolean
  noResultsComponent?: ReactNode
  queryParams: any
  updateQueryParam: (newValues: any) => void
}

const CustomTable: FC<CustomTablePros> = ({
  loading,
  error,
  data,
  total,
  columns,
  rowClick,
  selectable,
  noResultsComponent,
  queryParams,
  updateQueryParam,
}) => {
  //TODO consider movig this state outside the component
  const [selected, setSelected] = useState<string[]>([])
  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const currentPage = useMemo(() => queryParams.skip / queryParams.limit, [queryParams.skip, queryParams.limit])
  const emptyRows = useMemo(
    () => (currentPage > 0 ? Math.max(0, (1 + currentPage) * queryParams.limit - total) : 0),
    [currentPage, queryParams.limit, total]
  )

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof any) => {
    const isAsc = queryParams.sort_by === property && queryParams.sort_order === SortOrder.asc
    updateQueryParam({
      sort_by: property,
      sort_order: isAsc ? SortOrder.desc : SortOrder.asc,
    })
  }

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n._id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (_: MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  return (
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <CustomTableHead
        columns={columns}
        numSelected={selected.length}
        onRequestSort={handleRequestSort}
        onSelectAllClick={handleSelectAllClick}
        sortOrder={queryParams.sort_order}
        sortBy={queryParams.sort_by}
        rowCount={data?.length || 0}
        selectable={selectable}
      />

      <TableBody>
        {/* Loading skeleton loaderc */}
        {loading && (
          <TableSceletonLoader rowsCount={queryParams.limit} columnsCount={columns.length} selectable={selectable} />
        )}

        {/* No results message */}
        {total === 0 && (
          <TableRow style={{ height: (48 + 13) * queryParams.limit }}>
            <TableCell colSpan={columns.length + Number(!!selectable)} align="center">
              {noResultsComponent ? <>{noResultsComponent}</> : <TableNoResults />}
            </TableCell>
          </TableRow>
        )}

        {!loading &&
          total > 0 &&
          data.map((row, index) => (
            <TableRow
              key={row._id}
              hover
              tabIndex={-1}
              selected={isSelected(row._id)}
              aria-checked={isSelected(row._id)}
              onClick={() => rowClick && rowClick(row)}
              sx={{ cursor: !rowClick ? 'default' : 'pointer' }}>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleClick(event, row._id)}
                    color="primary"
                    checked={isSelected(row._id)}
                    inputProps={{
                      'aria-labelledby': `table-checkbox-${index}`,
                    }}
                  />
                </TableCell>
              )}
              {columns.map((col, index) => (
                <TableCell key={`${col.id}-${index}`} align={index ? 'center' : 'left'}>
                  {col.component && col.component(row)}
                  {col.format && col.format(row[col.id])}
                  {!col.component && !col.format && row[col.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        {/* Add empty rows to thE table to keep the same table height */}
        {emptyRows > 0 && (
          <TableRow style={{ height: (48 + 13) * emptyRows }}>
            {/* TODO Refactor this */}
            <TableCell colSpan={columns.length + Number(!!selectable)} />
          </TableRow>
        )}
      </TableBody>

      <CustomTableFooter
        colSpan={columns.length + Number(!!selectable)}
        currentPage={currentPage}
        total={total}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
      />
    </Table>
  )
}

export default CustomTable
