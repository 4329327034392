import React, { FunctionComponent, ReactElement, useState } from 'react'
import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from './TablePaginationActions'
import TableRow from '@mui/material/TableRow'
import { ColumnInterface, Row, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import TableSortLabel from '@mui/material/TableSortLabel'
import EditableCell from './EditableCell'
import LinearProgress from '@mui/material/LinearProgress'
import { MultiselectBarProps } from './MultiselectBar'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import makeStyles from '@mui/styles/makeStyles'
import DraggedSimualtionTableRow from '@/pages/ContentLibrary/SimulationsLibrary/DraggedSimualtionTableRow'

export type DefaultToolbarProps = {
  numSelected: number
  selectedRowIds: { [key: string]: any }
  preSearchFilterRows: any[]
  setSearchFilter: (value: string) => void
  searchFilter: string
}

type SimpleTableProps<DataObject> = {
  columns: ColumnInterface[]
  data: DataObject[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  highlightRows?: {
    variant: 'secondary' | 'primary' | 'success' | 'error'
    rows: number[]
  }
  TableToolbar?: FunctionComponent<any>
  toolbarProps?: DefaultToolbarProps
  multiToolbarProps?: Partial<MultiselectBarProps>
  alwaysShowToolbar?: boolean
  enableCheckbox?: boolean
  customCheckbox?: boolean
  onRowClick?: (row: Row<any>) => void
  initialPageSize?: number
  onChangeRowsPerPage?: (numberOfRows: number) => void
  onPageChange?: (pageNumber: number) => void
  count?: number
  noResultsContent: ReactElement
  maxContainerHeight?: number
  stickyHeader?: boolean
  isEditable?: boolean
  loading?: boolean
  draggabledSimulationRows?: boolean
}

const useStyles = makeStyles({
  table: ({ customCheckbox }: { customCheckbox: boolean }) => ({
    overflow: 'hidden',
    '& .MuiTableCell-head': {
      textAlign: 'center',
      '&:first-child': {
        paddingLeft: 15,
        textAlign: 'left',
      },
    },
    '& .MuiTableCell-body': {
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      '&:first-child': {
        textAlign: 'left',
      },
    },
    ...(customCheckbox
      ? {
          '& .MuiTableCell-head:nth-of-type(2)': {
            textAlign: 'left',
            paddingLeft: 15,
          },
          '& .MuiTableCell-body:nth-of-type(2)': {
            textAlign: 'left',
          },
        }
      : {}),
  }),
  row: {
    '&:hover': {
      backgroundColor: '#EFFCFE !important',
    },
    '&.isDisabled': {
      backgroundColor: '#f2f2f2',
      '&:hover': {
        backgroundColor: '#E0EDEF !important',
      },
    },
  },
})

export default function SimpleTable<DataObject>({
  columns,
  data,
  setData,
  TableToolbar,
  toolbarProps,
  multiToolbarProps,
  alwaysShowToolbar = true,
  onRowClick,
  enableCheckbox = true,
  customCheckbox = false,
  initialPageSize = 5,
  onPageChange,
  onChangeRowsPerPage,
  count,
  noResultsContent,
  maxContainerHeight,
  stickyHeader = false,
  isEditable = false,
  highlightRows,
  loading = false,
  draggabledSimulationRows = false,
}: SimpleTableProps<DataObject>) {
  const classes = useStyles({ customCheckbox })
  const [skipPageReset, setSkipPageReset] = useState(false)
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }

  const editableColumn = {
    Cell: EditableCell,
  }

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: isEditable ? editableColumn : undefined,
      initialState: {
        pageSize: initialPageSize,
      },
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => {
        const extraColumns: {}[] = []
        if (enableCheckbox) {
          extraColumns.push({
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          })
        }
        return [...extraColumns, ...columns]
      })
    }
  )

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage)
    onPageChange && onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value))
    onChangeRowsPerPage && onChangeRowsPerPage(Number(event.target.value))
  }

  const numSelected = Object.keys(selectedRowIds).length

  return (
    <div
      style={{
        position: 'relative',
        maxHeight: maxContainerHeight,
        paddingBottom: '50px',
      }}
    >
      <TableContainer style={{ maxHeight: maxContainerHeight }}>
        {TableToolbar && (alwaysShowToolbar || numSelected > 0) && (
          <TableToolbar
            numSelected={numSelected}
            selectedRowIds={selectedRowIds}
            selectedFlatRows={(selectedFlatRows || []).map((r) => r.original)}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            {...toolbarProps}
            {...{
              label: multiToolbarProps?.label,
              actions: multiToolbarProps?.actions?.map((action) => ({
                ...action,
                callback: () => toggleAllRowsSelected(false),
              })),
            }}
          />
        )}
        {loading && <LinearProgress />}
        <MaUTable stickyHeader={stickyHeader} {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => {
                  const cellProps = column?.cellProps || {}
                  const headerProps =
                    column.id === 'selection'
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps())
                  return (
                    <TableCell {...headerProps} {...cellProps}>
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          IconComponent={() =>
                            column.isSorted && (
                              <FontAwesomeIcon
                                icon={column.isSortedDesc ? faCaretDown : (faCaretUp as IconProp)}
                                width={'20px'}
                              />
                            )
                          }
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row)
                if (draggabledSimulationRows) return <DraggedSimualtionTableRow row={row} />
                return (
                  <TableRow
                    className={classes.row + (row.original.disabled ? ' isDisabled' : '')}
                    hover={!!onRowClick}
                    disableHover
                    onClick={() => {
                      return onRowClick ? onRowClick(row) : {}
                    }}
                    {...row.getRowProps()}
                    style={{
                      cursor: onRowClick ? 'pointer' : 'default',
                    }}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = cell.column?.cellProps || {}
                      return (
                        <TableCell {...cell.getCellProps()} {...cellProps}>
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableCell colSpan={columns.length + Number(enableCheckbox)}>{noResultsContent}</TableCell>
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
      {data.length > pageSize && (
        <TablePagination
          sx={{
            minWidth: 600,
            position: 'absolute',
            bottom: 0,
            right: '50px',
            mt: '50px',
          }}
          component="div"
          align="left"
          rowsPerPageOptions={[5, 10, 25, 50]}
          colSpan={columns.length}
          count={count ?? data.length}
          rowsPerPage={pageSize}
          page={pageIndex}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </div>
  )
}
