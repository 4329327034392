import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UploadDialog from './UploadDialog'
import { Office } from '../../../api/offices/offices'
import { find, isEmpty } from 'lodash'
import { DuplicateUserErrorData, NewUserParams, useNewUser } from '../../../api/users/new-user'
import { array, mixed, object, string, ValidationError } from 'yup'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { Group } from '../../../api/groups/groups'
import { useNewGroup } from '../../../api/groups/new-group'
import useToast from '../../hooks/useToast'
import CsvDuplicatesAlert from './CsvDuplicatesAlert'
import MergeDuplicatesAlert from './MergeDuplicateUsersAlert/MergeDuplicateUsersAlert'

type BulkUserUploadModalProps = {
  offices: Office[]
  groups: Group[]
}

export default function BulkUserUploadModal({ offices, groups }: BulkUserUploadModalProps) {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const [createNewUser, { isLoading }] = useNewUser()
  const [isOpen, setIsOpen] = useState(false)
  const [csvDuplicatesCount, setCsvDuplicatesCount] = useState(0)
  const [dbDuplicateUsers, setDbDuplicateUsers] = useState<DuplicateUserErrorData[]>([])
  const [fileName, setFileName] = useState('')
  const [uploadedUsers, setUploadedUsers] = useState<NewUserParams[]>([])
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [highlightedRows, setHighlightedRows] = useState<number[]>([])
  const [isParsingCSV, setIsParsingCSV] = useState(false)
  const [rawData, setRawData] = useState<{ [key: string]: string }[]>([])
  const [createNewGroup] = useNewGroup()
  const groupsRef = useRef<{ [groupName: string]: string }>({})
  groups?.forEach((group) => {
    if (!groupsRef.current[group.name.toLowerCase()]) {
      groupsRef.current[group.name.toLowerCase()] = group._id
    }
  })

  function handleOpen() {
    setIsOpen(true)
  }

  function handleClose() {
    setIsOpen(false)
    handleClear()
  }

  function handleClear() {
    setIsParsingCSV(false)
    setFileName('')
    setErrorMessages([])
    setHighlightedRows([])
    setRawData([])
    setUploadedUsers([])
  }

  const newUserSchema = object().shape({
    first_name: string().required(t('bulkUserUpload.errors.missingFirstName')).nullable(),
    last_name: string().nullable(),
    username: string()
      .required(t('bulkUserUpload.errors.missingEmail'))
      .email(t('bulkUserUpload.errors.invalidEmail'))
      .nullable(),
    phone_number: string()
      .test({
        name: 'phone-number-validation',
        test: (value) => (value ? isPossiblePhoneNumber(value) : true),
        message: t('bulkUserUpload.errors.invalidPhone'),
      })
      .matches(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/g, {
        message: t('bulkUserUpload.errors.invalidPhone'),
      })
      .nullable(),
    office_id: mixed()
      .required(t('bulkUserUpload.errors.missingOffice'))
      .oneOf(
        offices.map((o) => o._id),
        t('bulkUserUpload.errors.noSuchOffice')
      )
      .nullable(),
  })

  const fileUploadSchema = array().of(newUserSchema)

  const validateFileFields = useCallback((object: { [key: string]: any }) => {
    // check if the CSV has all the required fields and that field names are correct
    const requiredFields = ['first_name', 'email', 'office']
    const correctFieldNames = [...requiredFields, 'last_name', 'phone_number', 'groups']
    const inputKeys = Object.keys(object).filter((key) => !!key)
    const corruptFields = inputKeys.filter((key) => !correctFieldNames.includes(key))
    const missingFields = requiredFields.filter((requiredField) => !inputKeys.includes(requiredField))
    if (missingFields.length && corruptFields.length) {
      errorToast(
        t('bulkUserUpload.errors.missingAndCorruptFields', {
          missingFields: missingFields.join(', '),
          corruptFields: corruptFields.join(', '),
        })
      )
      return false
    } else if (missingFields.length) {
      errorToast(
        t('bulkUserUpload.errors.missingFields', {
          missingFields: missingFields.join(', '),
        })
      )
      return false
    } else if (corruptFields.length) {
      errorToast(
        t('bulkUserUpload.errors.corruptFields', {
          corruptFields: corruptFields.join(', '),
        })
      )
      return false
    }
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const parseUserData = useCallback(
    async (user: any): Promise<NewUserParams | undefined> => {
      // we check this condition to make sure the csv doesn't have lines where all values are 'null'
      if (isEmpty(Object.values(user).filter((i) => !!i))) {
        return undefined
      } else {
        const { office, email, phone_number, groups: csvGroupsString, ...rest } = user
        const csvGroupsArray = csvGroupsString
          ? csvGroupsString
              .toString()
              .split(',')
              .map((group) => group?.toLowerCase()?.trim())
          : []
        const officeObj = !office
          ? undefined
          : find(offices, (office) => office.name.toLowerCase().match(user.office.toLowerCase())) ?? {
              // this is to trigger the NoSuchOffice alert, instead of the Missing Office alert, incase there is no match.
              _id: 'no such office',
            }
        const groupIds: string[] = []
        for (const groupsToCreateElement of csvGroupsArray) {
          if (groupsRef.current[groupsToCreateElement]) {
            groupIds.push(groupsRef.current[groupsToCreateElement])
          } else {
            try {
              const newGroup = await createNewGroup({
                name: groupsToCreateElement,
                users: [],
                offices: officeObj && officeObj?._id !== 'no such office' ? [officeObj?._id] : [],
                managers: [],
              })
              if (newGroup) {
                groupIds.push(newGroup._id)
                groupsRef.current[groupsToCreateElement] = newGroup._id
              }
            } catch (e) {
              console.log(e)
              // This is a workaround due to a weird bug where the group id isn't found at first in the groupsRef object
              const refs = { ...groupsRef.current }
              if (refs[groupsToCreateElement]) {
                groupIds.push(refs[groupsToCreateElement])
              }
            }
          }
        }
        return {
          ...rest,
          username: email,
          office_id: officeObj?._id,
          phone_number,
          member_of: groupIds,
        }
      }
    },
    [createNewGroup, offices]
  )

  const handleParsingError = useCallback((users: NewUserParams[], e: any) => {
    setIsParsingCSV(false)
    const badRowIndices = e?.inner?.map((error) => error.path.match(/\[([^)]+)\]/)[1]) || []
    setHighlightedRows(badRowIndices)
    const validUsers = users.filter((user, index) => !badRowIndices.includes(index.toString()))
    setUploadedUsers(validUsers)
    const messages =
      e?.inner?.map(
        (error: ValidationError) => `Row #${parseInt(error.path.match(/\[([^)]+)\]/)[1]) + 1}: ${error.message}`
      ) || []
    setErrorMessages(messages)
  }, [])

  function countDuplicatesInCsvUsers(users: NewUserParams[]) {
    const usersByEmail: { [key: string]: NewUserParams } = {}
    const usersByPhone: { [key: string]: NewUserParams } = {}
    let duplicateCount = 0
    users.forEach((user) => {
      const { username, phone_number } = user
      const emailAlreadyExists = username && usersByEmail[username]
      const phoneAlreadyExists = phone_number && usersByPhone[phone_number]

      if (emailAlreadyExists || phoneAlreadyExists) {
        duplicateCount++
      }

      if (!emailAlreadyExists) {
        usersByEmail[username] = user
      }

      if (!phoneAlreadyExists) {
        usersByPhone[phone_number] = user
      }
    })
    return duplicateCount
  }

  const getNewUsersParamsList = useCallback(
    async (data: any[]): Promise<{ users: NewUserParams[]; duplicateCount: number }> => {
      const users: NewUserParams[] = []

      for (const user of data) {
        // transform csv data into user object
        const parsedUser = await parseUserData(user)
        if (parsedUser) {
          users.push(parsedUser)
        }
      }
      const duplicateCount = countDuplicatesInCsvUsers(users)
      return { users, duplicateCount }
    },
    [parseUserData]
  )

  const processRawCsvData = useCallback(
    async (data: any[]) => {
      if (data.length) {
        // here we only validate the first row of the csv to check if the structure is correct
        // full validation is done later against the schema
        const isValidStructure = validateFileFields(data[0])
        if (!isValidStructure) {
          handleClear()
          return
        }
      }

      const { users, duplicateCount } = await getNewUsersParamsList(data)
      if (duplicateCount > 0) {
        handleClear()
        setCsvDuplicatesCount(duplicateCount)
        return
      }

      // validate user inputs against schema
      fileUploadSchema
        .validate(users, { abortEarly: false })
        .then((value) => {
          setIsParsingCSV(false)
          setUploadedUsers(users)
          setErrorMessages([])
          setHighlightedRows([])
        })
        .catch((e) => {
          handleParsingError(users, e)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileUploadSchema, handleParsingError, parseUserData, validateFileFields]
  )

  useEffect(() => {
    processRawCsvData(rawData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData])

  function formatPhoneNumbers(csvRow: { [key: string]: any }) {
    // if there's a phone number and it doesn't start with a +, we add a + to it
    if (csvRow?.phone_number) {
      const updatedPhoneNumber = csvRow.phone_number.toString().startsWith('+')
        ? csvRow.phone_number.toString()
        : `+${csvRow?.phone_number}`

      return { ...csvRow, phone_number: updatedPhoneNumber }
    } else {
      return csvRow
    }
  }

  function handleUploadCSV(data, fileInfo) {
    setIsParsingCSV(true)
    setFileName(fileInfo.name)
    setRawData(data.map(formatPhoneNumbers))
  }

  async function handleCreateUsers(values: NewUserParams[]) {
    try {
      await createNewUser(values)
      successToast(t('users.newUserSuccess'))
      handleClose()
    } catch (e) {
      const errorData = e?.response?.data
      if (errorData?.data?.failed_users && errorData?.status_code === 409) {
        setDbDuplicateUsers(errorData.data.failed_users)
        throw e
      }
      const errorMessage = errorData?.message ?? t('users.errors.faliedNewUser')
      errorToast(errorMessage)
      throw e
    }
  }

  async function createNewUsers() {
    await handleCreateUsers(uploadedUsers)
  }

  function checkIsUserDuplicated(user: NewUserParams) {
    return dbDuplicateUsers.some(({ user_input }) => {
      return (
        user.username === user_input.username || (user.phone_number && user.phone_number === user_input.phone_number)
      )
    })
  }

  // after merging duplicate users, remove them from the uploaded users list and create the rest
  async function handleMergeUsersSuccess() {
    const validUsers = uploadedUsers.filter((uploadedUser) => !checkIsUserDuplicated(uploadedUser))
    if (validUsers.length) {
      await handleCreateUsers(validUsers)
    } else {
      handleClose()
      successToast(t('users.newUserSuccess'))
    }
  }

  return (
    <>
      <Button onClick={handleOpen} variant={'text'} sx={{ fontWeight: 'regular' }}>
        {t('bulkUserUpload.bulkUpdateUsers')}
      </Button>
      <CsvDuplicatesAlert
        open={!!csvDuplicatesCount}
        handleClose={() => setCsvDuplicatesCount(0)}
        duplicateCount={csvDuplicatesCount}
      />
      <MergeDuplicatesAlert
        duplicateUsers={dbDuplicateUsers}
        open={dbDuplicateUsers.length > 0}
        handleClose={() => setDbDuplicateUsers([])}
        onMergeUsersSuccess={handleMergeUsersSuccess}
      />
      <UploadDialog
        open={isOpen}
        handleClose={handleClose}
        handleClear={handleClear}
        handleUploadFile={handleUploadCSV}
        fileName={fileName}
        onSubmit={createNewUsers}
        errorMessages={errorMessages}
        highlightedRows={highlightedRows}
        isLoading={isLoading || isParsingCSV}
        numberOfUploadedUsers={uploadedUsers.length}
        rawFileData={rawData}
        updateRawFileData={setRawData}
      />
    </>
  )
}
