import { Box, styled } from '@mui/material'

export const CheckboxWrapper = styled(Box)(() => ({
  '& span': {
    fontSize: '12px',
  },
}))
export const Lock = styled(Box)(() => ({
  position: 'absolute',
  right: '54px',
  transform: 'translate(0px, -30px)',
}))

export const FieldsWrapper = styled(Box)(() => ({
  padding: '10px',
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
}))
export const ButtonsWrapper = styled(Box)(() => ({
  padding: '0px 20px 10px',
  display: 'flex',
  gap: 40,
  flexDirection: 'row',
  justifyContent: 'center',
}))
export const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#05DBF2',
  alignItems: 'center',
  padding: '15px 26px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#000',
  borderRadius: '10px 10px 0px 0px',
}))

export const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  width: 430,
  backgroundColor: 'white',
  p: 4,
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '10px',
}))
