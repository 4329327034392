import withStyles from '@mui/styles/withStyles'
import { Switch, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAiSimulationContext } from '../state'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import { theme } from '@/theme/theme'

const PersonalizeTemplateToggle = () => {
  const { setPersonalizedTemplate, personalizedTemplate } = useAiSimulationContext()

  const { t } = useTranslation()

  const CustomSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: theme.palette.cyan[500],
      },
      '&$checked + $track': {
        backgroundColor: theme.palette.cyan[500],
        outline: `1px solid ${theme.palette.blueGray[800]}`,
        opacity: 1,
      },
    },
    checked: {},
    track: {
      backgroundColor: theme.palette.blueGray[50],
      outline: `1px solid ${theme.palette.blueGray[800]}`,
      width: 30,
      borderRadius: 15,
    },
    thumb: {
      marginTop: theme.spacing(0.5),
      marginLeft: personalizedTemplate ? -1 : 5,
      width: 12,
      height: 12,
      boxShadow: 'none',
      backgroundColor: personalizedTemplate ? theme.palette.blueGray[800] : theme.palette.grey[400],
    },
  })(Switch)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
      <Typography>{t('simulationLibrary.ai.personalize')}</Typography>
      <CustomSwitch
        onChange={(e) => {
          setPersonalizedTemplate(e.target.checked)
        }}
        name="checked"
        color="primary"
        checked={personalizedTemplate}
      />
      <InfoTooltip
        title={t('simulationLibrary.ai.personalizeTooltip')}
        style={{ height: 15, color: theme.palette.blueGray[800], marginLeft: theme.spacing(-1) }}
      />
    </Box>
  )
}

export default PersonalizeTemplateToggle
