import axios from 'axios'
import { API_URL } from '../constants'
import { QueryConfig, useQuery } from 'react-query'
import { ServerError } from '../types'
import { Quiz } from '../quizzes/quiz-by-id'

export type EducationModule = {
  title: string
  description: string
  template_thumbnail?: string
  video_tracks: {
    [language: string]: string
  }
  subtitle_tracks: {
    [language: string]: string
  }
  duration: number
  tags: string[]
  languages: string[]
  updated_at?: number
  id: string // video assets has _id
  // _id: string
  client_id?: string
  copyrights?: boolean
  is_custom?: boolean
  saved_assets?: { [assetId: string]: boolean }
  quizzes?: Quiz[]
  additional_resources?: {
    [type: string]: string
  }
}

export async function getAllEducationModules() {
  const url = `${API_URL}/education_modules/all`
  const { data } = await axios.post(url, {}, { withCredentials: true })
  return data.data
}

export function useEducationModules(options?: QueryConfig<any>) {
  return useQuery<EducationModule[], ServerError>('modules', getAllEducationModules, options)
}
