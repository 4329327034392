import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { ServerError } from '../types'
import { paramsSerializer } from '../utils/serialize-array'

export type Member = {
  _id: string
  username: string
  client_id: string
  phone_number: string
  first_name: string
  last_name: string
  enabled: boolean
  member_of: string[]
  state: string
  office_id: string
  last_campaign: number
  awareness_score: string
  awareness_last_30_days: number[]
  engagement: { day: string; value: number }[]
  domain_verified: boolean
  campaigns: {
    day: string
    value: number
    counter: number
    events: {
      email_open: number
      sms_link_click: number
      email_link_click: number
      submit: number
      reported: number
      feedback_link_click: number
    }
  }[]
  exposure: { label: string; value: number }[]
  saved_assets?: string[]
}

export type AllMembersQueryVariables = {
  skip: number
  limit: number
  sort_by: string
  sort_order: string
  groups: string[]
  offices: string[]
  scores: string[]
  status: string[]
  search: string
  languages: string[]
  timezones: string[]
}

type AllMembersResponse = {
  total: number
  results: Member[]
}
const getMembers = async (
  params: AllMembersQueryVariables = {
    skip: 0,
    limit: 10,
    sort_by: 'first_name',
    sort_order: 'desc',
    groups: [],
    offices: [],
    scores: [],
    status: [],
    search: '',
    languages: [],
    timezones: [],
  }
) => {
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => value !== ''))
  const url = `${API_URL}/members/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params: filteredParams,
    paramsSerializer,
  })
  return data
}

export const useMembers = (variables?: AllMembersQueryVariables) => {
  return useQuery<AllMembersResponse, ServerError>({
    queryKey: ['members', { ...variables }],
    queryFn: () => getMembers(variables),
    placeholderData: keepPreviousData,
  })
}
