import Checkbox from '@/common/components/Inputs/Checkbox'
import TextField from '@/common/components/Inputs/TextField'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Popover, Typography, styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import Modal from '@mui/material/Modal'
//@ts-ignore
import { useOrganizationUpdate } from '@/api/organizations/update'
import { getErrorMessage } from '@/api/utils/get-error'
import useToast from '@/common/hooks/useToast'
import { OrganizationAccountType } from '@/types/organizations'
import LoadingAnimation from '../MobilePhonePreview/LoadingAnimation'
import { ButtonsWrapper, CheckboxWrapper, FieldsWrapper, ModalConent, TitleBar } from './ModalStyledComponents'
import { ModalProps } from './TreeChart'
import { DOMAIN_REGEX } from '@/api/constants'

const EditModal: FC<ModalProps> = ({ open, setOpen, organization }) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const { errorToast, successToast } = useToast()
  const { mutateAsync: editOrganization, isPending } = useOrganizationUpdate()

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEdit = async (values: {
    organizationName: string
    domain: string
    domainVerified: boolean
    allowAdd: boolean
    numberOfChildren: number
  }) => {
    try {
      await editOrganization({
        id: organization.id,
        params: {
          name: values.organizationName,
          domain: !values.domainVerified ? values.domain : '',
          type: values.allowAdd ? OrganizationAccountType.partner : OrganizationAccountType.client,
        },
      })

      handleClose()
      successToast(t('accountCenter.editModal.updateSuccess'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('accountCenter.editModal.updateFailed'))
    }
  }

  const popOverOpen = Boolean(anchorEl)

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .min(2, t('accountCenter.editModal.tooShort'))
      .max(50, t('accountCenter.editModal.tooLong'))
      .required(t('accountCenter.editModal.organizationNameRequired')),
    domain: Yup.string()
      .matches(DOMAIN_REGEX, t('accountCenter.editModal.domainWarning'))
      .required(t('accountCenter.editModal.domainWarning')),
    allowAdd: Yup.boolean(),
  })

  const initialValues = {
    organizationName: organization.name,
    domain: organization?.domain?.domain,
    domainVerified: organization?.domain?.verified,
    allowAdd: organization.type === OrganizationAccountType.partner,
    numberOfChildren: organization.number_of_children,
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalConent>
        <TitleBar>
          <Typography variant="h6" component="h2" fontFamily="Montserrat">
            {t('accountCenter.editModal.title')}
          </Typography>
        </TitleBar>
        <Box sx={{ p: '16px', overflowY: 'auto', maxHeight: '90vh' }}>
          {isPending && <LoadingAnimation />}
          <Formik onSubmit={handleEdit} initialValues={initialValues} validationSchema={validationSchema}>
            {({ values, dirty }) => (
              <Form>
                <FieldsWrapper>
                  <Box sx={{ height: '50px' }}>
                    <TextField
                      label={values.organizationName && t('accountCenter.editModal.organizationName')}
                      name="organizationName"
                      placeholder={t('accountCenter.editModal.organizationName')}
                    />
                  </Box>
                  <Box sx={{ height: '50px' }}>
                    <TextField
                      label={values.organizationName && t('accountCenter.editModal.domain')}
                      disabled={values.domainVerified}
                      name="domain"
                      placeholder={t('accountCenter.editModal.domain')}
                    />
                    {values.domainVerified && (
                      <Lock onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                        <FontAwesomeIcon icon={faLock as IconProp} />
                      </Lock>
                    )}
                    <Popover
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={popOverOpen}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography sx={{ p: 1, fontSize: '12px' }}>
                        <span>{t('accountCenter.editModal.cantEdit')}</span>
                        <br />
                        <span>{t('accountCenter.editModal.goSettingPage')}</span>
                      </Typography>
                    </Popover>
                  </Box>
                  <CheckboxWrapper sx={{ height: '50px', fontSize: '10px' }}>
                    <Checkbox
                      name="allowAdd"
                      disabled={values.numberOfChildren > 0}
                      label={t('accountCenter.editModal.allowAdd')}
                      checked={values.allowAdd}
                    />
                  </CheckboxWrapper>
                </FieldsWrapper>
                <ButtonsWrapper>
                  <Button variant="outlined" onClick={handleClose}>
                    {t('accountCenter.editModal.cancel')}
                  </Button>
                  <Button type="submit" disabled={!dirty} variant="contained">
                    {t('accountCenter.editModal.submit')}
                  </Button>
                </ButtonsWrapper>
              </Form>
            )}
          </Formik>
        </Box>
      </ModalConent>
    </Modal>
  )
}

const Lock = styled(Box)(() => ({
  position: 'absolute',
  right: '54px',
  transform: 'translate(0px, -30px)',
}))

export default EditModal
