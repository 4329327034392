import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from '@tanstack/react-query'

type MemberRequest = {
  id: string
}

type MemberObject = {
  _id: string
  first_name: string
  last_name: string
  username: string
  phone_number: string
  office_id: string
  member_of: string[]
  awareness_score: number
  awareness_last_30_days: number[]
}

const getMemberById = async ({ id }: MemberRequest) => {
  const url = `${API_URL}/members/${id}/`
  const { data } = await axios.get(url, { withCredentials: true })
  return data
}

export const useMemberById = ({ id }: MemberRequest) => {
  return useQuery({
    queryKey: ['members', id],
    queryFn: () => {
      return getMemberById({ id })
    },
  })
}
