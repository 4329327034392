import React, { useMemo, useState } from 'react'
import { useTheme, styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useParams } from 'react-router-dom'
import { Button, Typography, Paper, Grid, Box } from '@mui/material'
import LoadingContainer from '../../common/components/LoadingContainer/LoadingContainer'
import NoResultsContainer from '../../common/components/NoResultsContainer/NoResultsContainer'
import { useTranslation } from 'react-i18next'
import useToast from '../../common/hooks/useToast'
import AwarenessScore from '../../common/components/AwarenessScore/AwarenessScore'
import UserPerformanceChart, {
  UserPerformanceChartData,
} from '../../common/components/UserPerformanceChart/UserPerformanceChart'
import { useOffice } from '../../api/offices/office'
import { EditOfficeParams, useEditOffice } from '../../api/offices/edit-office'
import CreateOfficeModal from '../../common/components/CreateOfficeModal/CreateOfficeModal'
import OfficeTables from './OfficeTables'

import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import { theme } from '@/theme/theme'

export default function OfficeProfile() {
  const theme = useTheme()
  const classes = useStyles()
  const { _id } = useParams()
  const { data, isLoading } = useOffice(_id)
  const [editOffice] = useEditOffice()
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()

  function openEditOfficeModal() {
    setEditModalIsOpen(true)
  }

  async function handleEditOffice(params: EditOfficeParams) {
    try {
      await editOffice({ ...params, _id })
      successToast(t('officeProfile.officeEdited'))
    } catch (e) {
      const errorMessage = e.response?.data?.message ?? t('officeProfile.errors.faliedEditOffice')
      errorToast(errorMessage)
      throw e
    }
  }

  const chartData = useMemo((): UserPerformanceChartData[] => {
    const failedUsers: UserPerformanceChartData = {
      id: 'failed',
      data: [],
    }
    const passedUsers: UserPerformanceChartData = {
      id: 'passed',
      data: [],
    }

    if (data?.campaign_stats) {
      Object.entries(data.campaign_stats)
        .slice(23)
        .forEach(([key, value]) => {
          failedUsers.data.push({ x: key, y: value.failed })
          passedUsers.data.push({ x: key, y: value.passed })
        })
    }
    return [failedUsers, passedUsers]
  }, [data])

  return (
    <>
      <div className={classes.root}>
        <StyledBreadcrumbBox>
          <CustomBreadcrumbs lastBreadcrumbSuffix={`| ${data?.name}` || ''} />
        </StyledBreadcrumbBox>
        <div className={classes.header}>
          <div className={classes.flexContainer}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title} gutterBottom variant={'h5'}>
                {data ? data.name : ''}
              </Typography>
            </div>

            <div className={classes.buttonsContainer}>
              <Button
                className={classes.button}
                color={'inherit'}
                sx={{ borderColor: theme.palette.cyan[500] }}
                variant={'outlined'}
                onClick={() => openEditOfficeModal()}
              >
                {t('officeProfile.editOffice')}
              </Button>
            </div>
          </div>
        </div>
        <Grid container className={classes.chartContainer}>
          <Grid item xs={4}>
            <Paper className={classes.cywarenessScore}>
              <AwarenessScore
                monthlyScores={data?.awareness_last_30_days ?? []}
                score={data?.awareness_score ?? 0}
                loading={isLoading}
              />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.usersChart}>
              <Typography gutterBottom variant={'subtitle2'}>
                {t('officeProfile.officePerformance')}
              </Typography>
              <UserPerformanceChart data={chartData} />
            </Paper>
          </Grid>
        </Grid>

        {isLoading ? (
          <LoadingContainer />
        ) : data ? (
          <OfficeTables currentOfficeId={_id} />
        ) : (
          <NoResultsContainer text={'officeProfile.noResults'} />
        )}
      </div>
      <CreateOfficeModal
        showButton={false}
        createNewOffice={() => {}}
        saveOfficeEdit={handleEditOffice}
        open={editModalIsOpen}
        setOpen={setEditModalIsOpen}
        office={data}
      />
    </>
  )
}

const StyledBreadcrumbBox = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { height: '100%', width: '100%', padding: theme.spacing(1, 4, 4) },
    header: {
      marginBottom: theme.spacing(3),
    },
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
    },
    chartsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      width: '100%',
      marginBottom: theme.spacing(2),
      height: 200,
    },
    chartContainer: {
      marginBottom: theme.spacing(2),
    },
    cywarenessScore: {
      flex: 1,
      padding: theme.spacing(2, 3),
      marginRight: theme.spacing(2),
      height: 280,
    },
    usersChart: {
      padding: theme.spacing(2, 3),
      flex: 4,
      height: 280,
    },
    tableContainer: {
      flex: 1,
    },
    buttonsContainer: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    button: {
      marginRight: theme.spacing(2),
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    title: { marginRight: theme.spacing(2) },
  })
)
