import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import { AllMembersQueryVariables, useMembers } from '@/api/members/get'
import { User } from '@/api/users/users'
import PaginatingTableV2 from '@/common/components/Tables/PaginatingTable'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import { Group } from '@/api/groups/groups'
import { Office } from '@/api/offices/offices'
import { MultiSelectBarV2Props } from '@/common/components/Tables/MultiSelectBarV2'
import { Serialized } from '@/api/utils/serialize-array'
import { Tooltip } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import { TABLE_SIZE_REFERENCE_KEYS, getDefaultTableSize, setDefaultTableSize } from '@/common/utils/table-size'
import { useGroups } from '@/api/groups/groups'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import InTableMessageWithAnimation from '@/common/components/Tables/InTableMessageWithAnimation'

type UserTableProps = {
  handleEditUser: (user: User) => void
  handleOnboardUser: (ids: string[]) => void
  handleOffboardUser: (ids: string[]) => void
  handleAssignToGroups: (ids: string[]) => void
  handleAssignToOffice: (ids: string[]) => void
  setQueryFilters: React.Dispatch<React.SetStateAction<AllMembersQueryVariables>>
  groups?: Group[]
  offices?: Serialized<Office>
  queryFilters: {
    search: string
    groups: string[]
    offices: string[]
    status: boolean[]
    skip: number
    limit: number
    sort_by: string
    sort_order: string
    scores: string[]
  }
}

export default function UsersTable({
  handleEditUser,
  handleOffboardUser,
  handleOnboardUser,
  handleAssignToGroups,
  handleAssignToOffice,
  setQueryFilters,
  offices,
  queryFilters,
}: UserTableProps) {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  // @ts-ignore
  const { data: queryData, isLoading } = useMembers(queryFilters)
  const { results: members, total } = queryData ?? { total: 0, results: [] }

  const [pageCount, setPageCount] = useState(0)

  const { data: groups } = useGroups()
  const [pageIndex, setPageIndex] = useState(0)
  const groupsById = useMemo(() => {
    const byId = {}
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }
    return byId
  }, [groups])

  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE)

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE, pageSize)
  }

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy, sortOrder }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        skip: prevState.limit * pageIndex,
        limit: pageSize,
        sort_by: sortBy || prevState.sort_by,
        sort_order: sortOrder || prevState.sort_order,
      }))
      setPageCount(Math.ceil(total / pageSize))
      setPageIndex(pageIndex)
    },
    [total]
  )

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: t('users.usersTable.email'),
        accessor: 'username',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        Cell: ({ value }) => value || '-',
      },
      {
        Header: t('users.usersTable.groups'),
        accessor: 'groups',
        Cell: (data) => {
          const value = data.row.original.member_of
          const groupsByName = []
          if (Array.isArray(value) && value.length > 0) {
            value.forEach((groupId) => {
              const recipientGroup = groupsById[groupId]
              recipientGroup && groupsByName.push(recipientGroup.name)
            })
          }
          return groupsByName.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}
            >
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '4px 4px',
                  fontSize: '14px',
                }}
              >
                {groupsByName[0]}
              </div>
              {groupsByName.length > 1 ? (
                <Tooltip
                  placement={'right'}
                  title={groupsByName
                    .slice(1)
                    .map((group) => group)
                    .join(', ')}
                >
                  <div
                    style={{
                      padding: '3px 8px',
                      gap: '10px',
                      borderRadius: '20px',
                      border: '1px solid #000',
                      marginLeft: '5px',
                      verticalAlign: 'middle',
                    }}
                  >
                    {`+${groupsByName.length - 1}`}
                  </div>
                </Tooltip>
              ) : null}
            </div>
          ) : (
            '-'
          )
        },
      },
      {
        Header: t('users.usersTable.office'),
        accessor: 'office_id',
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 'auto',
              }}
            >
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '2px 4px',
                  fontSize: '14px',
                }}
              >
                {offices?.byId[value] ? offices.byId[value]?.name : ''}
              </div>
            </div>
          )
        },
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => {
          return <HumanizedScore score={value} />
        },
      },
      {
        Header: t('users.usersTable.status'),
        accessor: 'state',
        Cell: ({ value }) => (value === 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive')),
      },
      {
        id: 'delete',
        width: 80,
        align: 'center',
        Cell: (data) => {
          const user = data.row.original
          const userId = user._id
          const isActive = user.state === 'active'
          return (
            <MoreMenu
              items={[
                {
                  label: 'users.usersTable.edit',
                  onClick: () => {
                    handleEditUser(user)
                  },
                },
                {
                  label: isActive ? t('users.usersTable.deactivate') : t('users.usersTable.activate'),
                  textColor: isActive ? '#c40956' : '#007059',
                  fontWeight: 'bold',
                  onClick: () => {
                    isActive ? handleOffboardUser([userId]) : handleOnboardUser([userId])
                  },
                },
              ]}
              ariaLabel={'users.usersTable.moreActions'}
            />
          )
        },
      },
    ],
    [t, offices]
  )

  const multiSelectActions: MultiSelectBarV2Props['actions'] = [
    {
      label: 'Activate',
      onClick: (userIds) => {
        handleOnboardUser(userIds)
      },
      variant: 'button',
    },
    {
      label: 'Deactivate',
      onClick: (userIds) => {
        handleOffboardUser(userIds)
      },
      variant: 'button',
    },
    {
      label: 'Assign To Groups',
      onClick: (userIds) => {
        handleAssignToGroups(userIds)
      },
      variant: 'button',
    },
    {
      label: 'Assign to Office',
      onClick: (userIds) => {
        handleAssignToOffice(userIds)
      },
      variant: 'button',
    },
  ]

  function handleNavigateToUserProfile({ original }) {
    navigateTo(`/recipients/users/${original._id}`)
  }
  if (isLoading) return <LoadingContainer />

  return (
    <PaginatingTableV2
      multiSelectBarProps={{
        label: 'users.usersTable.user_count',
        actions: multiSelectActions,
      }}
      noResultsContent={<NoResultsComp officesCount={offices?.array.length} />}
      columns={columns}
      data={members ?? []}
      fetchData={fetchData}
      loading={isLoading}
      pageCount={pageCount}
      count={total}
      onRowClick={handleNavigateToUserProfile}
      initialPageSize={defaultRowsPerPage}
      currentPageIndex={pageIndex}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}

const NoResultsComp: FC<{ officesCount?: number }> = ({ officesCount }) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation>
      <p>{t(officesCount ? 'noResults.noMemberAdded' : 'noResults.noOfficeAdded')}</p>
      <p>
        <Trans
          i18nKey={officesCount ? 'noResults.addMember' : 'noResults.addOffice'}
          components={{
            1: <NavLink to={officesCount ? '/recipients/members' : '/recipients/offices'} />,
          }}
        />
      </p>
    </InTableMessageWithAnimation>
  )
}
