import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Alert from "../../Alert/Alert";
import { useTranslation } from "react-i18next";
import { DuplicateUserErrorData } from "../../../../api/users/new-user";
import MergeUsersSelector from "./MergeUsersSelector";
import { useBulkEditUsers } from "../../../../api/users/bulk-user-edit";
import { getErrorMessage } from "../../../../api/utils/get-error";
import useToast from "../../../hooks/useToast";
import MergeAllDuplicates from "./MergeAllDuplicates";
import LoadingContainer from "../../LoadingContainer/LoadingContainer";

type MergeDuplicatesAlertProps = {
  duplicateUsers: DuplicateUserErrorData[];
  open: boolean;
  handleClose: () => void;
  onMergeUsersSuccess: () => void;
};

export default function MergeDuplicatesAlert({
  duplicateUsers = [],
  onMergeUsersSuccess,
  ...props
}: MergeDuplicatesAlertProps) {
  const classes = useStyles();
  const [bulkUpdateUsers, { isLoading }] = useBulkEditUsers();
  const [showSelectEach, setShowSelectEach] = useState(false);
  const { errorToast } = useToast();
  const { t } = useTranslation();

  function mergeUserData(userData: DuplicateUserErrorData) {
    const { existing_user, user_input } = userData;
    return { _id: existing_user._id, ...user_input };
  }

  async function updateUsers(users: DuplicateUserErrorData[]) {
    try {
      const updatedUsers = users.map(mergeUserData);
      if (updatedUsers.length) {
        await bulkUpdateUsers(updatedUsers);
      }
      await onMergeUsersSuccess();
      handleClose();
    } catch (e) {
      console.log(e);
      const errorMessage = getErrorMessage(e);
      errorToast(
        errorMessage ||
          t("bulkUserUpload.errors.duplicatesInDbAlert.errors.failedToMerge")
      );
    }
  }

  async function handleMergeAll() {
    await updateUsers(duplicateUsers);
  }

  function handleClose() {
    props.handleClose();
    setShowSelectEach(false);
  }

  return (
    <Alert
      confirmButton={false}
      dialogTitle={t("bulkUserUpload.errors.duplicatesInDbAlert.title")}
      dialogContent={
        <div className={classes.root}>
          {isLoading && (
            <div className={classes.loadingOverlay}>
              <LoadingContainer />
            </div>
          )}
          {showSelectEach ? (
            <MergeUsersSelector
              users={duplicateUsers}
              handleMergeUsers={updateUsers}
            />
          ) : (
            <MergeAllDuplicates
              mergeAll={handleMergeAll}
              duplicateUserCount={duplicateUsers.length}
              showSelectEach={() => setShowSelectEach(true)}
            />
          )}
        </div>
      }
      handleClose={handleClose}
      open={props.open}
    />
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    loadingOverlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255,255,255,0.5)",
      zIndex: 1000,
    },
  })
);
