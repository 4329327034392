import { Box, Typography, styled } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { theme } from '@/theme/theme'
import PlanPickerCard from './PickerPlanCard'

const PlanPicker: FC<{
  liteCost: number
  tailoredMemberCost: number
  humanfirewallCost: number
  membersCount: number
  campaignsCount: number
  currentPlan: string | undefined
  currentSubscriptionTime: number | undefined
  setCurrentPlan: Dispatch<SetStateAction<string | undefined>>
  setCrrentSubscriptionTime: Dispatch<SetStateAction<number | undefined>>
}> = ({
  liteCost,
  tailoredMemberCost,
  humanfirewallCost,
  membersCount,
  campaignsCount,
  currentPlan,
  currentSubscriptionTime,
  setCurrentPlan,
  setCrrentSubscriptionTime,
}) => {
  const { t } = useTranslation()
  return (
    <Box width="100%">
      <StyledBackgroundBox>
        <Box display="flex" gap={theme.spacing(2)}>
          <PlanPickerCard
            title="Lite"
            color={theme.palette.cyan[50]}
            memberCost={liteCost}
            membersCount={membersCount}
            campaignsCount={campaignsCount}
            setCurrentPlan={setCurrentPlan}
            setCrrentSubscriptionTime={setCrrentSubscriptionTime}
            currentPlan={currentPlan}
            currentSubscriptionTime={currentSubscriptionTime}
          />
          <PlanPickerCard
            title="Tailored"
            color={theme.palette.cyan[100]}
            memberCost={tailoredMemberCost}
            membersCount={membersCount}
            campaignsCount={1}
            setCurrentPlan={setCurrentPlan}
            setCrrentSubscriptionTime={setCrrentSubscriptionTime}
            currentPlan={currentPlan}
            currentSubscriptionTime={currentSubscriptionTime}
          />
          <PlanPickerCard
            title="Human Firewall"
            color={theme.palette.cyan[500]}
            memberCost={humanfirewallCost}
            membersCount={membersCount}
            campaignsCount={1}
            setCurrentPlan={setCurrentPlan}
            setCrrentSubscriptionTime={setCrrentSubscriptionTime}
            currentPlan={currentPlan}
            currentSubscriptionTime={currentSubscriptionTime}
          />
        </Box>
        <Typography fontSize={12} fontWeight={700} marginTop={theme.spacing(3)}>
          {t('pricingCalculator.disclaimer')}
        </Typography>
      </StyledBackgroundBox>
    </Box>
  )
}

const StyledBackgroundBox = styled(Box)(() => ({
  background: theme.palette.cyan[50],
  padding: theme.spacing(2),
  margin: theme.spacing(0, -1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '10px',
}))

export default PlanPicker
