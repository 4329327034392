import axios from "axios";
import { API_URL } from "../constants";
import { useMutation } from "react-query";
import { ServerError } from "../types";

type ChangePasswordVariables = {
  new_password: string;
  current_password: string;
};

async function changePassword(params: ChangePasswordVariables) {
  await axios.put(`${API_URL}/profile/password/`, params, {
    withCredentials: true,
  });
}

export function useChangePassword() {
  return useMutation<void, ServerError, ChangePasswordVariables>(
    changePassword,
    { throwOnError: true }
  );
}
