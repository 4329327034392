import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../context/Auth";
import { useClientDetails } from "../api/client/client";

function ProtectedRoute({ children, redirectTo = "/login", enabled = true }) {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  useClientDetails();

  useEffect(() => {
    if (!isLoading && (!user || !enabled)) {
      navigate(redirectTo);
    }
  }, [user, isLoading, enabled, navigate, redirectTo]);

  if (isLoading) {
    return <></>;
  }

  return user && enabled ? children : null;
}

export default ProtectedRoute;
