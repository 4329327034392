import { Box, Typography, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { DIGITS_REGEX, LOWERCASE_REGEX, SPECIAL_CHARACTERS_REGEX, UPPERCASE_REGEX } from '@/api/constants'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

//TODO: move to the palette!

const RED = '#EB115D'
const GREEN = '#66B632'
const ORANGE = '#F88700'
const GRAY = '#DDDDDD'
const DARK_GRAY = '#979797'

const PasswordStrengthEvaluation: FC<{ password: string }> = ({ password }) => {
  const { t } = useTranslation()
  const isUpperLowerCase = UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password) ? 1 : 0
  const number = DIGITS_REGEX.test(password) ? 1 : 0
  const specialCharcter = SPECIAL_CHARACTERS_REGEX.test(password) ? 1 : 0
  const minLength = password.length > 7 ? 1 : 0
  const strength = isUpperLowerCase + number + specialCharcter + minLength
  const grade = strength > 3 ? 'STRONG' : strength > 1 ? 'MEDIUM' : 'WEAK'
  return (
    <>
      <RowBox>
        <ColorLine color={strength > 3 ? GREEN : strength > 1 ? ORANGE : RED} />
        <ColorLine color={strength > 3 ? GREEN : strength > 1 ? ORANGE : GRAY} />
        <ColorLine color={strength > 3 ? GREEN : strength > 1 ? GRAY : GRAY} />
        <Typography style={{ margin: '0px 6px' }}>{grade}</Typography>
      </RowBox>
      <SmallTypography>
        <Trans
          i18nKey={'settings.admins.password.mustContain'}
          components={{
            1: <span style={{ fontWeight: 'bold' }} />,
          }}
        />
      </SmallTypography>
      <RowBox>
        <Box style={{ marginRight: '40px' }}>
          <CheckWrapper>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginRight: '8px', color: isUpperLowerCase ? GREEN : DARK_GRAY }}
            />
            <SmallTypography>{t('settings.admins.password.upperLowercase')}</SmallTypography>
          </CheckWrapper>
          <CheckWrapper>
            <FontAwesomeIcon icon={faCircleCheck} style={{ marginRight: '8px', color: number ? GREEN : DARK_GRAY }} />
            <SmallTypography>{t('settings.admins.password.number')}</SmallTypography>
          </CheckWrapper>
        </Box>
        <Box style={{ marginRight: '40px' }}>
          <CheckWrapper>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginRight: '8px', color: specialCharcter ? GREEN : DARK_GRAY }}
            />
            <SmallTypography>{t('settings.admins.password.specialCharacter')}</SmallTypography>
          </CheckWrapper>
          <CheckWrapper>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ marginRight: '8px', color: minLength ? GREEN : DARK_GRAY }}
            />
            <SmallTypography>{t('settings.admins.password.minCharacters')}</SmallTypography>
          </CheckWrapper>
        </Box>
      </RowBox>
    </>
  )
}

const RowBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))
const CheckWrapper = styled(RowBox)(() => ({
  marginBottom: '10px',
}))
const ColorLine = styled(Box)(({ color }: { color: string }) => ({
  width: '75px',
  height: '9px',
  background: color,
  borderRadius: '15px',
  margin: ' 0px 4px',
}))
const SmallTypography = styled(Typography)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '12px',
}))

export default PasswordStrengthEvaluation
