import Alert from '../Alert/Alert'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'

type CsvDuplicatesAlertProps = {
  duplicateCount: number
  open: boolean
  handleClose: () => void
}

export default function CsvDuplicatesAlert({ duplicateCount, ...props }: CsvDuplicatesAlertProps) {
  const classes = useStyles()

  const { t } = useTranslation()
  return (
    <Alert
      dialogTitle={t('bulkUserUpload.errors.duplicatesInCsvAlert.title')}
      dialogContent={
        <div className={classes.root}>
          <FontAwesomeIcon icon={faTriangleExclamation as IconProp} className={classes.icon} />
          <Typography variant={'subtitle1'}>
            {t(
              duplicateCount > 100
                ? 'bulkUserUpload.errors.duplicatesInCsvAlert.messageOver100'
                : 'bulkUserUpload.errors.duplicatesInCsvAlert.message',
              {
                count: duplicateCount,
              }
            )}
          </Typography>
        </div>
      }
      buttonLabel={t('bulkUserUpload.errors.duplicatesInCsvAlert.button')}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { display: 'flex', alignItems: 'center' },
    icon: { marginRight: theme.spacing(2) },
  })
)
