import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import { DashboardAnalyticsObject } from "../../../api/dashboard-analytics";
import NoResultsContainer from "../NoResultsContainer/NoResultsContainer";
import GaugeScoremeter from "../GaugeScoremeter/GaugeScoremeter";

import noResultsImage from "@/assets/images/flat-smiley.svg";

type AwarenessScoreProps = {
  score: DashboardAnalyticsObject["awareness_score"];
  monthlyScores: DashboardAnalyticsObject["awareness_last_30_days"];
  loading: boolean;
};

export default function AwarenessScore({
  score,
  monthlyScores,
  loading = false,
}: AwarenessScoreProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const noData =
    !loading && (isNaN(score) || !monthlyScores?.length || score === -1);

  return (
    <div className={classes.root}>
      <div className={classes.slidesContainer}>
        <div
          className={classes.flexContainer}
          style={{ justifyContent: "space-between" }}
        >
          <div className={classes.flexContainer}>
            <Typography variant={"subtitle2"}>
              {t("awarenessMeter.title")}
            </Typography>
            <InfoTooltip title={t("awarenessMeter.tooltip")} />
          </div>
          {!noData ? (
            <Typography variant={"subtitle2"}>{score}</Typography>
          ) : null}
        </div>
        {noData ? (
          <div style={{ marginTop: 40 }}>
            <NoResultsContainer
              image={noResultsImage}
              text={"awarenessScore.noResults"}
            />
          </div>
        ) : (
          <>
            <div className={classes.scoreMeterContainer}>
              <GaugeScoremeter score={score || 0} hideTitle />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    slidesContainer: {
      height: 200,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    dotsContainer: {
      display: "flex",
      justifyContent: "center",
      zIndex: 10,
    },
    flexContainer: {
      display: "flex",
    },
    scoreMeterContainer: {
      height: 140,
    },
    lineChartContainer: {
      height: 160,
    },
  })
);
