import { Box, Paper, Typography, styled } from '@mui/material'
import { Parser as HtmlToReactParser } from 'html-to-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import WhatsAppPreview from '@/assets/images/DesktopPreview/whatsapp-frame.svg'
import WhatsAppTodayHeader from '@/assets/images/DesktopPreview/today-business-tags.svg'

const StyledPaper = styled(Paper)(({ theme }) => ({
  margin: 'auto',
  height: '690px',
  width: '1220px',
  backgroundImage: `url(${WhatsAppPreview})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(5),
  },
}))

const MainMessageContainer = styled(Box)(({ theme }) => ({
  width: 845.6,
  maxHeight: 587,
  margin: theme.spacing(6.4, 0, 0, 46.2),
  overflowY: 'auto',
  padding: theme.spacing(2),
}))

const Message = styled(({ isRtl, ...otherProps }: { isRtl?: boolean; [key: string]: any }) => (
  <Typography {...otherProps} />
))(({ theme, isRtl }) => ({
  backgroundColor: '#fff',
  borderRadius: '10px',
  width: 'fit-content',
  height: 'fit-content',
  padding: '20px',
  marginTop: theme.spacing(2),
  marginLeft: isRtl ? 'auto' : theme.spacing(4),
  marginRight: isRtl ? theme.spacing(4) : 'auto',
  direction: isRtl ? 'rtl' : 'ltr',
  '&.MuiTypography-root': {
    fontSize: 12,
  },
}))

const Sender = styled(Typography)(({ theme }) => ({
  width: 'fit-content',
  margin: theme.spacing(2, 0, 0, 53),
  position: 'absolute',
}))

const Image = styled('img')(() => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '50%',
}))

const htmlToReactParser = new (HtmlToReactParser as any)()

interface WhatsAppMessageProps {
  subject: string
  sender: string
  message: string
  isRtl?: boolean
}

const WhatsAppMessage: FC<WhatsAppMessageProps> = ({ message, sender, isRtl }) => {
  const { t } = useTranslation()

  message = message.replaceAll('{{link_click_url}}', 'https://tinyurl.com/')

  return (
    <StyledPaper>
      <Sender id="sender">{sender}</Sender>
      <MainMessageContainer>
        <Image src={WhatsAppTodayHeader} alt="Today" />
        <Message isRtl={isRtl}>{htmlToReactParser.parse(message)}</Message>
      </MainMessageContainer>
    </StyledPaper>
  )
}

export default WhatsAppMessage
