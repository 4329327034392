import { FC } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleExclamation, faTriangleExclamation, faSquareExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useClientDetails, ClientDetailsResult } from '@/api/client/client'
import { useGroups } from '@/api/groups/groups'
import useCreateMembersGroups from '@/api/members/createMembersGroups'
import useCreateMembersOffices from '@/api/members/createMembersOffices'
import useDeleteMember from '@/api/members/delete'
import { AllMembersQueryVariables } from '@/api/members/get'
import { useOffices } from '@/api/offices/offices'
import { useDisableUsers } from '@/api/users/disable-users'
import { EditUserParams, useEditUser } from '@/api/users/edit-user'
import { useEnableUsers } from '@/api/users/enable-users'
import { User } from '@/api/users/users'
import { getErrorMessage } from '@/api/utils/get-error'
import AddUserGroupsModal from '@/common/components/AddUserGroupsModal/AddUserGroupsModal'
import AddUserOfficeModal from '@/common/components/AddUserOfficeModal/AddUserOfficeModal'
import EditUserModal from '@/common/components/EditUserModal/EditUserModal'
import useToast from '@/common/hooks/useToast'
import DeletionModal from '../DeletionModal'
import InactiveMembersModal from './InactiveMembersModal'
import UsersTable from './UsersTable'
import { theme } from '@/theme/theme'

type UsersTabProps = {
  setQueryFilters: React.Dispatch<React.SetStateAction<AllMembersQueryVariables>>
  queryFilters: {
    search: string
    groups: string[]
    offices: string[]
    status: boolean[]
    languages: string[]
    timezones: string[]
    limit: number
    skip: number
    sort_order: string
    sort_by: string
    scores: string[]
  }
}

export default function UsersTab({ queryFilters, setQueryFilters }: UsersTabProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [editUser] = useEditUser()
  const { mutateAsync: deleteMember } = useDeleteMember()
  const [disableUsers] = useDisableUsers()
  const [enableUsers] = useEnableUsers()
  const { mutateAsync: createMembersOffices } = useCreateMembersOffices()
  const { mutateAsync: createMembersGroups } = useCreateMembersGroups()

  const { data: offices } = useOffices()
  const { data: groups } = useGroups()
  const { data: clientDetails } = useClientDetails()

  const [deleteMemberAlertIsOpen, setDeleteMemberAlertIsOpen] = useState(false)
  const [inactiveMemberAlertIsOpen, setInactiveMemberAlertIsOpen] = useState(false)

  const [userForDeletion, setUserForDeletion] = useState<string>('')
  const [usersForDeactivation, setMembersForDeactivation] = useState<string[]>([])
  const [usersForAddGroup, setUsersForAddGroup] = useState<string[]>([])
  const [usersForAddOffice, setUsersForAddOffice] = useState<string[]>([])
  const [userToEdit, setUserToEdit] = useState<User>()

  const [userModalIsOpen, setUserModalIsOpen] = useState(false)
  const [addGroupModalOpen, setAddGroupModalOpen] = useState(false)
  const [addOfficeModalOpen, setAddOfficeModalOpen] = useState(false)

  const quotaMetPercentage = Math.ceil(
    ((clientDetails?.members?.active ?? 0) / (clientDetails?.users_quota ?? 0)) * 100
  )

  const exceptionAllowance = Math.ceil((clientDetails?.users_quota ?? 0) * 1.05) - (clientDetails?.members?.active ?? 0)
  const isQuotaExceeded = exceptionAllowance <= 0 || quotaMetPercentage > 105

  const quotaWarningMessage =
    (clientDetails?.users_quota ?? 0) <= 10
      ? ''
      : isQuotaExceeded
      ? t('users.membersQuotaWarning.exceededQuotaMessage', {
          parentAccount: clientDetails?.parent_name || "organization's",
        })
      : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
      ? t('users.membersQuotaWarning.metQuotaMessage', {
          exceptionAllowance: exceptionAllowance,
          metQuotaPercentage: quotaMetPercentage,
          parentAccount: clientDetails?.parent_name || "organization's",
        })
      : quotaMetPercentage >= 90
      ? t('users.membersQuotaWarning.warningMessage', { metQuotaPercentage: quotaMetPercentage })
      : ''

  const { successToast, errorToast } = useToast()

  function openEditUserModal(user: User) {
    setUserToEdit(user)
    setUserModalIsOpen(true)
  }

  async function handleAddGroupsModal(userIds: string[]) {
    setUsersForAddGroup(userIds)
    setAddGroupModalOpen(true)
  }

  async function handleCloseAddGroupsModal() {
    setUsersForAddGroup([])
    setAddGroupModalOpen(false)
  }

  async function handleAddOfficeModal(userIds: string[]) {
    setUsersForAddOffice(userIds)
    setAddOfficeModalOpen(true)
  }

  async function handleCloseAddOfficeModal() {
    setUsersForAddOffice([])
    setAddOfficeModalOpen(false)
  }

  async function handleInactiveMemberAlert(userIds: string[]) {
    setMembersForDeactivation(userIds)
    setInactiveMemberAlertIsOpen(true)
  }

  async function handleCloseInactiveMemberAlert() {
    setMembersForDeactivation([])
    setInactiveMemberAlertIsOpen(false)
  }

  async function handleUserDeleteAlert(userId: string) {
    setUserForDeletion(userId)
    setDeleteMemberAlertIsOpen(true)
  }

  async function handleCloseUserDeleteAlert() {
    setUserForDeletion('')
    setDeleteMemberAlertIsOpen(false)
  }

  async function handleEditUser(params: EditUserParams) {
    try {
      await editUser(params)
      successToast(t('users.userEdited'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEditUser'))
      throw e
    }
  }

  async function handleDeleteUser() {
    try {
      await deleteMember({ id: userForDeletion })
      successToast(t('users.userDeleted'))
      setDeleteMemberAlertIsOpen(false)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedDeleteUser'))
      console.log(e)
    }
  }

  async function handleDisableUser() {
    try {
      await disableUsers({ users: usersForDeactivation })
      successToast(t('users.userDisabled'))
      handleCloseInactiveMemberAlert()
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedDisableUser'))
      console.log(e)
    }
  }

  async function handleEnableUser(users: string[]) {
    try {
      await enableUsers({ users })
      successToast(t('users.userEnabled'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('users.errors.faliedEnableUser'))
      console.log(e)
    }
  }

  async function handleAddGroups(values: any) {
    if (values.groups.length > 0) {
      try {
        await createMembersGroups(values)
        successToast('Groups Added Successfully')
        handleCloseAddGroupsModal()
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        errorToast(errorMessage || t('editUserModal.assignGroupsFail'))
        console.log(e)
      }
    }
  }

  async function handleAddOffice(values: any) {
    if (values.office) {
      try {
        await createMembersOffices(values)
        successToast('Office assigned Successfully')
        handleCloseAddOfficeModal()
      } catch (e) {
        const errorMessage = getErrorMessage(e)
        errorToast(errorMessage || t('editUserModal.assignOfficeFail'))
        console.log(e)
      }
    }
  }

  return (
    <div className={classes.root}>
      <AddUserGroupsModal
        handleClose={handleCloseAddGroupsModal}
        handleSave={handleAddGroups}
        open={addGroupModalOpen}
        userIds={usersForAddGroup}
        groups={groups}
      />
      <AddUserOfficeModal
        handleClose={handleCloseAddOfficeModal}
        handleSave={handleAddOffice}
        open={addOfficeModalOpen}
        userIds={usersForAddOffice}
        offices={offices?.array}
      />
      <DeletionModal
        confirmDeleteOpen={deleteMemberAlertIsOpen}
        handleCancelDelete={handleCloseUserDeleteAlert}
        deleteIntegrationHandler={handleDeleteUser}
        title={'Delete Member'}
        description={'Are you sure you want to delete this member?'}
      />
      <InactiveMembersModal
        confirmInactiveOpen={inactiveMemberAlertIsOpen}
        handleConfirmInactive={handleDisableUser}
        handleCancelInactive={handleCloseInactiveMemberAlert}
      />
      <EditUserModal
        saveUserEdit={handleEditUser}
        open={userModalIsOpen}
        setOpen={setUserModalIsOpen}
        user={userToEdit}
        onClose={() => setUserToEdit(undefined)}
      />
      {quotaWarningMessage && (
        <Alert
          severity="warning"
          variant="filled"
          style={{
            margin: '8px 16px',
            backgroundColor: isQuotaExceeded
              ? theme.palette.red[900] + '10'
              : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
              ? theme.palette.orange[400] + '15'
              : theme.palette.blue[500] + '10',
            color: theme.palette.blueDianne[900],
          }}
          icon={
            <MessageIcon
              isQuotaExceeded={isQuotaExceeded}
              quotaMetPercentage={quotaMetPercentage}
              clientDetails={clientDetails}
            />
          }>
          <Typography variant="h3" style={{ margin: '0 0 8px', fontSize: '20px', fontWeight: 'bold' }}>
            {t('users.membersQuotaWarning.title')}
          </Typography>
          {quotaWarningMessage}
        </Alert>
      )}

      <UsersTable
        handleEditUser={openEditUserModal}
        handleOnboardUser={handleEnableUser}
        handleOffboardUser={handleInactiveMemberAlert}
        handleAssignToGroups={handleAddGroupsModal}
        handleAssignToOffice={handleAddOfficeModal}
        offices={offices}
        groups={groups}
        queryFilters={queryFilters}
        setQueryFilters={setQueryFilters}
      />
    </div>
  )
}

const MessageIcon: FC<{
  isQuotaExceeded: boolean
  quotaMetPercentage: number
  clientDetails?: ClientDetailsResult
}> = ({ isQuotaExceeded, quotaMetPercentage, clientDetails }) => {
  const determineIcon = () => {
    if (isQuotaExceeded) {
      return faSquareExclamation
    } else if (quotaMetPercentage >= 100 && quotaMetPercentage <= 105) {
      return faTriangleExclamation
    } else if (quotaMetPercentage >= 90 && (clientDetails?.users_quota ?? 0) >= 10) {
      return faCircleExclamation
    } else {
      return faTriangleExclamation as IconProp
    }
  }

  const iconColor = isQuotaExceeded
    ? theme.palette.red[900]
    : quotaMetPercentage >= 100 && quotaMetPercentage <= 105
    ? theme.palette.orange[400]
    : theme.palette.blue[500]

  return <FontAwesomeIcon icon={determineIcon() as IconProp} color={iconColor} />
}

const useStyles = makeStyles((theme) =>
  createStyles({
    menuWrapper: {
      padding: '6px 22px',
      marginTop: '10px',
      width: '270px',
    },
    menuItem: {
      width: '270px',
    },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
    },
    tabsRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tabs: {
      borderBottom: '1px solid #D8D8D8',
      width: 'fit-content',
      borderRadius: '0px',
    },
    flexContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 2),
    },
    bulkClasses: {
      marginLeft: '8px',
      fontSize: '16px',
      fontWeight: 300,
    },
    tab: {
      fontSize: 16,
      fontWeight: 500,
      color: '#000000',
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textTransform: 'none',
      },
      '&:not(.Mui-selected)': {
        color: '#979797',
      },
    },
  })
)
