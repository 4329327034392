import axios from 'axios';
import { API_URL } from '../constants';
import { useMutation, useQueryCache } from 'react-query';
import { ServerError } from '../types';

export type DisableUsersParam = {
  users: string[];
};

export async function disableUsers(params: DisableUsersParam): Promise<string> {
  const url = `${API_URL}/users/disable`;
  const { data } = await axios.post(url, params, { withCredentials: true });
  return data.data;
}

export function useDisableUsers() {
  const queryCache = useQueryCache();
  return useMutation<string, ServerError, DisableUsersParam>(disableUsers, {
    throwOnError: true,
    //@ts-ignore
    onSettled: () => {
      queryCache.invalidateQueries('users');
    },
  });
}
