import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { useUpdateClient } from '@/api/client/update-client'
import { countriesArray } from '@/assets/countries'
import UploadImageModal from '@/common/components/UploadImageModal/UploadImageModal'
import useToast from '@/common/hooks/useToast'
import { FieldWrapper, StyledTextField, Title } from './ConfigurationsStyledComponents'

type OrganizationSettingsProps = {
  name?: string
  country?: string
  logo?: string
}

const OrganizationSettings: FC<OrganizationSettingsProps> = ({ name, country, logo }) => {
  const { t } = useTranslation()
  const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClient()
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
  const [initialClientValues, setInitialClientValues] = useState({
    organizationName: name,
    organiztionCountry: country,
  })
  const [logoUrl, setLogoUrl] = useState(logo || '')
  const { successToast, errorToast } = useToast()
  const [isSelectFocused, setIsSelectFocused] = useState(false)
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().min(2),
  })

  async function handleOnFinishImageUpload(url: string) {
    await updateClient({ logo: url })
    setLogoUrl(url)
  }

  const handleSubmit = async (values: {
    organizationName: string | undefined
    organiztionCountry: string | undefined
  }) => {
    const { organizationName, organiztionCountry } = values
    if (organizationName && organiztionCountry) {
      try {
        await updateClient({ name: organizationName, origin_country: organiztionCountry })
        setInitialClientValues(values)
        successToast(t('settings.configurationTab.organizationSettings.organizationUpdated'))
      } catch (e) {
        errorToast(t('settings.configurationTab.organizationSettings.errors.organizationUpdateFailed'))
      }
    }
  }

  return (
    <Box style={{ paddingBottom: '20px' }}>
      <Title>{t('settings.configurationTab.organizationSettings.organizationSettings')}</Title>
      <LogoContainer
        src={logoUrl}
        onClick={() => {
          setImageModalIsOpen(true)
        }}
      >
        <UploadOnHover hasLogo={logoUrl !== ''}>
          <FontAwesomeIcon fontSize={40} icon={faImage as IconProp} />
          <Typography>
            {logoUrl !== ''
              ? t('settings.configurationTab.organizationSettings.replacePhoto')
              : t('settings.configurationTab.organizationSettings.uploadPhoto')}
          </Typography>
        </UploadOnHover>
      </LogoContainer>
      <Formik onSubmit={handleSubmit} initialValues={initialClientValues} validationSchema={validationSchema}>
        {({ setFieldValue, isSubmitting, values, touched, setTouched, dirty, errors, isValid }) => (
          <Form>
            <FieldWrapper>
              <StyledTextField
                size="small"
                value={values.organizationName}
                label={t('settings.configurationTab.organizationSettings.organiztaionName')}
                name="organizationName"
                placeholder={t('settings.configurationTab.organizationSettings.organiztaionName')}
                onChange={(value) => {
                  setFieldValue('organizationName', value.target.value)
                  setTouched({ ...touched, ['organizationName']: true }, true)
                  console.log(touched, dirty, errors)
                }}
                error={touched.organizationName && errors.organizationName ? true : false}
              />
            </FieldWrapper>
            <FormControl fullWidth size="small" variant="outlined" sx={{ '& fieldset': { borderRadius: '14px' } }}>
              <InputLabel htmlFor="style-select">
                {t('settings.configurationTab.organizationSettings.organizationOrigin')}
              </InputLabel>
              <Select
                size="small"
                value={values.organiztionCountry}
                name="organizationName"
                onFocus={() => setIsSelectFocused(true)}
                onBlur={() => setIsSelectFocused(false)}
                placeholder={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                onChange={(value) => {
                  setFieldValue('organiztionCountry', value.target.value)
                  setTouched({ ...touched, ['organiztionCountry']: true }, true)
                }}
                input={
                  <OutlinedInput
                    notched={!!values.organiztionCountry?.length || isSelectFocused}
                    label={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                  />
                }
              >
                {countriesArray.map((country) => {
                  return <MenuItem value={country.code}>{country.name}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FieldWrapper>
              <Button
                sx={{ width: '100%' }}
                type="submit"
                disabled={
                  (initialClientValues.organizationName === values.organizationName &&
                    initialClientValues.organiztionCountry === values.organiztionCountry) ||
                  !isValid ||
                  isSubmitting ||
                  isUpdatingClient
                }
                variant="contained"
              >
                {t('settings.configurationTab.organizationSettings.saveCahnge')}
              </Button>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
      <UploadImageModal
        open={imageModalIsOpen}
        setOpen={setImageModalIsOpen}
        onFinishUpload={handleOnFinishImageUpload}
      />
    </Box>
  )
}

const UploadOnHover = styled(Box)(({ hasLogo }: { hasLogo: boolean }) => ({
  background: 'rgba(1,1,1,0.6)',
  color: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: hasLogo ? '0%' : '50%',
  transition: 'opacity 0.5s',
  cursor: 'pointer',
  '&:hover': {
    opacity: '100%',
  },
}))

const LogoContainer = styled(Box)(({ src }: { src: string }) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '200px',
  border: '1px solid #B8B8B8',
  borderRadius: '15px',
  height: '120px',
  flex: 1,
  marginBottom: '15px',
  overflow: 'clip',
}))

export default OrganizationSettings
