import axios from "axios";
import { API_URL } from "../constants";
import { useMutation, useQueryCache } from "react-query";
import { ServerError } from "../types";

import { CurrentUser } from "../profile/current-user";

type LoginCredentials = {
  username: string;
  password: string;
  recaptcha: string;
};

type LoginResponse = CurrentUser & { mfa?: boolean };

async function login(credentials: LoginCredentials): Promise<LoginResponse> {
  const url = `${API_URL}/auth/login/`;
  const { data } = await axios.post(
    url,
    { ...credentials },
    { withCredentials: true }
  );
  return data;
}

export function useLogin() {
  const queryCache = useQueryCache();
  return useMutation<LoginResponse, ServerError, LoginCredentials>(login, {
    throwOnError: true,
    onSuccess: (data) => {
      if (data?.username && !data?.mfa) {
        queryCache.setQueryData("user", data);
      }
    },
  });
}
