import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider, IconButton, InputBase, Tooltip, Typography, debounce, styled } from '@mui/material'
import dayjs from 'dayjs'
import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import { startCase } from 'lodash'

import { useAdmins } from '@/api/admins/admins'
import { useResendAdminInvite } from '@/api/admins/resend-invite'
import { useDisableAdmin } from '@/api/admins/disable-admin'
import { useEnableAdmin } from '@/api/admins/enable-admin'
import { useRemoveAdmin } from '@/api/admins/remove-admin'
import { US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import questionIcon from '@/assets/images/popup/question.svg'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Popup from '@/common/components/Popup'
import StatusFilter from '@/common/components/StatusFilter/StatusFilter'
import Table, { TalbeColumn } from '@/common/components/Table'
import useQueryParams from '@/common/hooks/useQueryParams'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { SortOrder } from '@/types/common'
import { Admin, Status } from '@/types/admins'

const INITIAL_QUERY_FILTERS = {
  username: '',
  full_name: '',
  invited_by: '',
  sort_by: 'full_name',
  sort_order: SortOrder.asc,
  status: [],
  skip: 0,
  limit: 10,
}

const AdminsTable: FC = () => {
  const { t } = useTranslation()
  const { errorToast, successToast } = useToast()
  const [chosenAdmin, setChosenAdmin] = useState<Admin>()
  const { mutateAsync: enableAdmin } = useEnableAdmin()
  const { mutateAsync: removeAdmin } = useRemoveAdmin()
  const { mutateAsync: disableAdmin } = useDisableAdmin()
  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const [searchDebounce, setSearchDebounce] = useState('')
  const [deleteWarningModalOpen, setDeleteWarningModalOpen] = useState(false)
  const [disableWarningModalOpen, setDisableWarningModalOpen] = useState(false)

  const { mutateAsync: resendAdminInvite } = useResendAdminInvite()
  const { data: admins, isError, isRefetching } = useAdmins({ query: queryParams as Admin })

  const { results: adminsData, total } = admins ?? { total: 0, results: [] }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchDebounce(e.target.value)
    debounce(() => {
      updateQueryParam({ full_name: e.target.value, username: e.target.value, invited_by: e.target.value })
    }, 500)()
  }
  const handleEnableAdmin = useCallback(
    async (_id: string) => {
      try {
        await enableAdmin(_id)
        successToast(t('settings.admins.table.adminEnabled'))
      } catch (e) {
        errorToast(t('settings.admins.table.errors.failedToEnable'))
      }
    },
    [enableAdmin, successToast, errorToast, t]
  )

  const handleDisableAdmin = async () => {
    try {
      await disableAdmin(chosenAdmin?._id || '')
      successToast(t('settings.admins.table.adminDisabled'))
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToDisable'))
    } finally {
      setChosenAdmin(undefined)
    }
  }

  const handleRemoveAdmin = async () => {
    try {
      await removeAdmin(chosenAdmin?._id || '')
      successToast(t('settings.admins.table.adminRemoved'))
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToRemove'))
    } finally {
      setChosenAdmin(undefined)
    }
  }

  const handleResendInvite = async (admin: Admin) => {
    try {
      await resendAdminInvite(admin._id)
      successToast(t('settings.admins.table.inviteResent'))
    } catch (e) {
      errorToast(t('settings.admins.table.errors.failedToResend'))
    }
  }

  const handleRemoveAdminModal = (admin: Admin) => {
    setChosenAdmin(admin)
    setDeleteWarningModalOpen(true)
  }
  const handleDisableAdminModal = (admin: Admin) => {
    setChosenAdmin(admin)
    setDisableWarningModalOpen(true)
  }
  const tableColumns: TalbeColumn[] = [
    {
      id: 'full_name',
      label: (
        <Typography fontWeight={theme.typography.fontWeightMedium} whiteSpace="nowrap">
          {startCase(t('settings.admins.table.fullName'))}
        </Typography>
      ),
      sortable: true,
      component: (data) => {
        return (
          <Tooltip title={data.full_name}>
            <Typography textOverflow="ellipsis" width={theme.spacing(22)} whiteSpace="nowrap" overflow="hidden">
              {data.full_name}
            </Typography>
          </Tooltip>
        )
      },
    },
    {
      id: 'username',
      label: (
        <Typography fontWeight={theme.typography.fontWeightMedium}>
          {startCase(t('settings.admins.table.email'))}
        </Typography>
      ),
      sortable: true,
      component: (data) => {
        return (
          <Box display="flex" justifyContent="center">
            <Typography
              align="left"
              textOverflow="ellipsis"
              width={theme.spacing(25)}
              whiteSpace="nowrap"
              overflow="hidden">
              {data.username}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'joined_date',
      label: (
        <Typography fontWeight={theme.typography.fontWeightMedium}>
          {startCase(t('settings.admins.table.joined'))}
        </Typography>
      ),
      sortable: true,
      component: (data) => {
        return (
          <Box display="flex" justifyContent="center">
            <Typography width={theme.spacing(14)} align="left">
              {dayjs.utc(data.joined_date).format(US_DATE_WITH_MONTH_NAME)}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'status',
      label: (
        <Typography fontWeight={theme.typography.fontWeightMedium}>
          {startCase(t('settings.admins.table.status'))}
        </Typography>
      ),
      sortable: true,
      component: (data) => {
        const { status } = data
        return (
          <Box display="flex" justifyContent="center">
            <Typography>{t(`settings.admins.table.${status}`)}</Typography>
          </Box>
        )
      },
    },
    {
      id: 'invited_by',
      label: (
        <Typography fontWeight={theme.typography.fontWeightMedium} whiteSpace="nowrap">
          {startCase(t('settings.admins.table.invitedBy'))}
        </Typography>
      ),
      sortable: true,
      component: (data) => {
        return (
          <Box display="flex" justifyContent="center">
            <Typography
              align="left"
              textOverflow="ellipsis"
              width={theme.spacing(22)}
              whiteSpace="nowrap"
              overflow="hidden">
              {data.invited_by.name}
            </Typography>
          </Box>
        )
      },
    },
    {
      id: 'options',
      label: '',
      sortable: true,
      component: (data) => {
        const admin = data
        const options = [
          {
            label: 'settings.admins.table.remove',
            textColor: '',
            onClick: () => {
              handleRemoveAdminModal(admin)
            },
          },
        ]
        if (admin.status === Status.disabled) {
          options.push({
            label: 'settings.admins.table.enable',
            textColor: theme.palette.green[800],
            onClick: () => {
              handleEnableAdmin(admin._id)
            },
          })
        } else if (admin.status === Status.enabled) {
          options.push({
            label: 'settings.admins.table.disable',
            textColor: theme.palette.red[900],
            onClick: () => {
              handleDisableAdminModal(admin)
            },
          })
        } else if (admin.status === Status.pending) {
          options.push({
            label: 'settings.admins.table.resend',
            textColor: '',
            onClick: () => {
              handleResendInvite(admin)
            },
          })
        }
        return <MoreMenu items={options} ariaLabel={'settings.admins.table.moreActions'} />
      },
    },
  ]
  const handleStatusChange = (newStatus: string[]) => {
    updateQueryParam({ ...queryParams, status: newStatus })
  }
  return (
    <StyledContainer>
      <Popup
        open={deleteWarningModalOpen}
        onClose={() => setDeleteWarningModalOpen(false)}
        onConfirm={() => {
          setDeleteWarningModalOpen(false)
          handleRemoveAdmin()
        }}
        buttonLabels={{
          confirm: t('settings.admins.deleteModal.confirmButton'),
          cancel: t('settings.admins.deleteModal.cancelButton'),
        }}
        icon={questionIcon}>
        <h4>{t('settings.admins.deleteModal.title')}</h4>
        <p>
          {t('settings.admins.deleteModal.message', {
            admin_name: chosenAdmin?.full_name,
          })}
        </p>
      </Popup>
      <Popup
        open={disableWarningModalOpen}
        onClose={() => setDisableWarningModalOpen(false)}
        onConfirm={() => {
          setDisableWarningModalOpen(false)
          handleDisableAdmin()
        }}
        buttonLabels={{
          confirm: t('settings.admins.disableModal.confirmButton'),
          cancel: t('settings.admins.disableModal.cancelButton'),
        }}
        icon={questionIcon}>
        <h4>{t('settings.admins.disableModal.title')}</h4>
        <p>
          {t('settings.admins.disableModal.message1', {
            admin_name: chosenAdmin?.full_name,
          })}
        </p>
        <p>{t('settings.admins.disableModal.message2')}</p>
      </Popup>
      <StyledFiltersBox>
        <StyledInputBase
          fullWidth
          value={searchDebounce}
          endAdornment={
            searchDebounce ? (
              <IconButton
                sx={{ height: 0 }}
                onClick={() => {
                  setSearchDebounce('')
                  updateQueryParam({
                    full_name: '',
                    username: '',
                  })
                }}>
                <FontAwesomeIcon icon={faX as IconProp} width={12} />
              </IconButton>
            ) : (
              <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} width={16} />
            )
          }
          placeholder={t('contentLibrary.search')}
          onChange={handleSearch}
        />
        <StatusFilter
          width={340}
          statuses={{ Active: 'enabled', Inactive: 'disabled', Pending: 'pending' }}
          values={queryParams.status}
          onChange={handleStatusChange}
          label={'Status'}
        />
      </StyledFiltersBox>
      <Divider></Divider>
      <Table
        loading={isRefetching}
        error={isError}
        data={adminsData || {}}
        total={total || 0}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
        columns={tableColumns}
        rowClick={() => {}}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)(() => ({
  margin: theme.spacing(0, -6),
}))

const StyledFiltersBox = styled(Box)(() => ({
  display: 'flex',
  padding: theme.spacing(0, 2),
  marginTop: theme.spacing(-5.5),
}))

const StyledInputBase = styled(InputBase)(() => ({
  margin: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '10px',
  padding: theme.spacing(0.5, 2),
}))
export default AdminsTable
