import { theme } from '@/theme/theme'
import { Box, Slider, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

import json from '@/assets/pricingCalculatorSettings.json'
import { StyledTextField } from './StyledTextField'

const discountData = json.discounts.members
const marks = discountData.count.map((count: number) => {
  return {
    value: count,
    label: (
      <Typography fontSize={12} display="flex" flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
        <b>{count}</b>
        Members
      </Typography>
    ),
  }
})

const MembersCountFiels: FC<{
  membersCount: number | undefined
  setMembersCount: Dispatch<SetStateAction<number | undefined>>
}> = ({ membersCount, setMembersCount }) => {
  const getLabel = () => {
    if (!!membersCount) {
      for (let i = 0; i < discountData.count.length; i++) {
        if (membersCount < discountData.count[i]) return discountData.count[i] + ' <'
      }
      return '>' + discountData.count[discountData.count.length - 1]
    }
    return discountData.count[0] + ' <'
  }
  const getDiscount = () => {
    if (!!membersCount && membersCount >= discountData.count[0]) {
      for (let i = 1; i < discountData.count.length; i++) {
        if (membersCount < discountData.count[i]) return discountData.discount[i - 1] + '% Discount'
      }
      return discountData.discount[discountData.count.length - 1] + '% Discount'
    }
    return 'No Discount'
  }
  return (
    <Box
      sx={{
        paddingBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <StyledTextField
        label="Number of Members"
        required
        type="number"
        value={membersCount && membersCount > 0 ? membersCount : ''}
        onBlur={() => {
          if (!membersCount) setMembersCount(1)
        }}
        onChange={(e) => {
          const inputValue = e.target.value
          if (inputValue !== '') {
            const count = Number(inputValue)
            if (count >= 0) {
              setMembersCount(count)
            }
          } else {
            setMembersCount(0)
          }
        }}
      />

      <Box sx={{ width: '1100px', marginTop: theme.spacing(4) }}>
        <Slider
          value={membersCount || 0}
          marks={marks}
          valueLabelDisplay="on"
          valueLabelFormat={() => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 400,
                backgroundColor: theme.palette.blueDianne[900],
                color: 'white',
                borderRadius: '4px',
              }}>
              <span>{getLabel()}</span>
              <span style={{ color: theme.palette.cyan[500], fontWeight: 700 }}> {getDiscount()}</span>
            </Box>
          )}
          min={0}
          max={11000}
          sx={{
            margin: '20px 50px',
            pointerEvents: 'none',

            '& .MuiSlider-thumb': {
              width: 5,
              height: 5,
              background: theme.palette.cyan[500],
              '&:before': {
                boxShadow: 'none',
              },
              '&:hover': {
                boxShadow: 'none',
              },
              '&.Mui-active': {
                boxShadow: 'none',
              },
            },
            ' .MuiSlider-track': {
              background: theme.palette.cyan[500],
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            ' .MuiSlider-rail': {
              background: theme.palette.cyan[50],
              border: `1px solid ${theme.palette.grey[400]}`,
            },
            ' .MuiSlider-mark': {
              background: theme.palette.grey[400],
            },
            '& .MuiSlider-valueLabel': {
              backgroundColor: 'primary.main',
              borderRadius: '10px',
              background: theme.palette.blueDianne[900],
              fontSize: 12,
            },
          }}
        />
      </Box>
    </Box>
  )
}
export default MembersCountFiels
