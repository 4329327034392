import { Box, Typography, styled } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

import { theme } from '@/theme/theme'
import PlanPickerCardSection from './PlanPickerCardSection'

const PlanPickerCard: FC<{
  title: string
  color: string
  memberCost: number
  membersCount: number
  campaignsCount: number
  currentPlan: string | undefined
  currentSubscriptionTime: number | undefined
  setCurrentPlan: Dispatch<SetStateAction<string | undefined>>
  setCrrentSubscriptionTime: Dispatch<SetStateAction<number | undefined>>
}> = ({
  title,
  color,
  memberCost,
  membersCount,
  campaignsCount,
  currentPlan,
  currentSubscriptionTime,
  setCurrentPlan,
  setCrrentSubscriptionTime,
}) => {
  return (
    <StyledContainer>
      <StyledTitleBox color={color}>
        <Typography fontSize={20} fontWeight={700} textAlign="center" color={theme.palette.blueDianne[900]}>
          {title}
        </Typography>
      </StyledTitleBox>
      <PlanPickerCardSection
        year={1}
        cost={memberCost}
        discount={0}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(1)
        }}
        selected={currentPlan === title && currentSubscriptionTime === 1}
      />
      <PlanPickerCardSection
        year={2}
        cost={memberCost}
        discount={6}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(2)
        }}
        selected={currentPlan === title && currentSubscriptionTime === 2}
      />
      <PlanPickerCardSection
        year={3}
        cost={memberCost}
        discount={12}
        membersCount={membersCount}
        campaignsCount={campaignsCount}
        relatedtPlan={title}
        setCurrentPlan={() => setCurrentPlan(title)}
        setCrrentSubscriptionTime={() => {
          setCrrentSubscriptionTime(3)
        }}
        selected={currentPlan === title && currentSubscriptionTime === 3}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)(() => ({
  border: '1px solid',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}))

const StyledTitleBox = styled(Box)(({ color }: { color: string }) => ({
  background: color,
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))
export default PlanPickerCard
