import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, IconButton, Paper } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Shepherd, { StepOptions } from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'

import { useCampaignMicrotraining } from '@/api/campaigns/getMicrotraining'
import { CAMPAIGN_TYPES, EVENT_TYPES, LogEventParams } from '@/api/events/log'
import { usePhishingSimulation } from '@/api/phishing-simulations/get'
import CywarnessHeaderLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import CywarenessLogo from '@/assets/images/logo-dark.svg'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useDeviceDetection, { DeviceType } from '@/common/hooks/useDeviceDetection'
import useLogEvent from '@/common/hooks/useLogEvent'
import isLanguageRTL from '@/common/utils/isLanguageRTL'
import { theme } from '@/theme/theme'
import { CampaignMicrotraining, MicrotrainingIndicator } from '@/types/campaigns'
import { PhishingIndicator, PhishingSimulation, PhishingSimulationVector } from '@/types/phishingSimulations'
import DevicePreview from './components/DevicePreview'
import Header from './components/Header'
import InvalidToken from './components/InvalidToken'

const ControlDots = ({ stepsCount, currentStepIndex }: { stepsCount: number; currentStepIndex: number }) => {
  const classes = useStyles()
  return (
    <Box className={classes.circles}>
      {Array.from({ length: stepsCount }).map((_, index) => {
        return (
          <Box
            key={index}
            className={currentStepIndex === index ? classes.currentCircle : classes.circle}
            id={'toStep' + index}
          />
        )
      })}
    </Box>
  )
}
const createShepherdTour = (
  data: CampaignMicrotraining | undefined,
  t: (key: string) => string,
  isRtl: boolean,
  logCampaignEvent: (() => void) | ((params: Omit<LogEventParams, 'campaignToken'>) => Promise<void>)
) => {
  const classes = useStyles()
  if (!data) return null

  const handleTrainingStepChange = (currentStep: number) => {
    var pageIteration = {
      current_page: currentStep + 1,
      total_pages: data.indicators.message.length + 2,
    }
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_PAGE_ITERATION,
      campaignType: CAMPAIGN_TYPES.EMAIL,
      additionalData: { page_iteration: pageIteration },
    })
  }

  const renderCloseButton = (tour: Shepherd.Tour) => {
    const closeButtonContainer = document.createElement('div')
    closeButtonContainer.style.position = 'absolute'
    closeButtonContainer.style.top = '10px'
    closeButtonContainer.style.right = '10px'
    ReactDOM.render(
      <IconButton
        onClick={() => {
          tour.cancel()
          logCampaignEvent({
            eventType: EVENT_TYPES.FEEDBACK_CLOSED,
            campaignType: CAMPAIGN_TYPES.EMAIL,
          })
        }}>
        <FontAwesomeIcon icon={faX as IconProp} fontSize={10} />
      </IconButton>,
      closeButtonContainer
    )

    return closeButtonContainer
  }

  const renderStepNumber = (index: number) => {
    const numberContainer = document.createElement('div')
    numberContainer.style.position = 'absolute'
    numberContainer.style.top = '-10px'
    numberContainer.style.left = '-10px'
    ReactDOM.render(<StyledStepNumberCircle>{index + 1}</StyledStepNumberCircle>, numberContainer)
    return numberContainer
  }

  const getStepButtons = (index: number) => [
    {
      text: ReactDOMServer.renderToStaticMarkup(
        <FontAwesomeIcon icon={(isRtl ? faArrowRight : faArrowLeft) as IconProp} />
      ),
      classes: classes.shepherdButton,
      action: () => {
        tour.back()
        handleTrainingStepChange(index)
      },
      disabled: index === 0,
    },
    {
      text: () =>
        ReactDOMServer.renderToStaticMarkup(
          <ControlDots stepsCount={data.indicators.message.length + 2} currentStepIndex={index} />
        ),
      classes: classes.shepherdButton,
      action: (e: any) => {
        if ((e.target.id as string).startsWith('toStep')) {
          tour.show(Number((e.target.id as string).charAt(6)))
        }
      },
    },
    {
      text: ReactDOMServer.renderToStaticMarkup(
        <FontAwesomeIcon icon={(isRtl ? faArrowLeft : faArrowRight) as IconProp} />
      ),
      classes: classes.shepherdButton,
      disabled: data.indicators.message.length + 1 === index,
      action: () => {
        tour.next()
        handleTrainingStepChange(index)
      },
    },
  ]

  const steps = [
    {
      id: 'welcome',
      title: t('feedbackPage.welcomeTitle'),
      text: t('feedbackPage.welcomeMessage'),
      attachTo: {
        element: '#title',
        on: 'bottom',
      },
      when: {
        show: function (this: Shepherd.Step) {
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
          }
        },
      },
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      buttons: getStepButtons(0),
    },
    ...(data.indicators.message || []).map((indicator: MicrotrainingIndicator, indicatorIndex: number) => ({
      id: `indicator-${indicator.selector}`,
      title: indicator.title,
      text: indicator.description,
      attachTo: {
        element: indicator.selector,
        on: 'bottom',
      },
      arrow: false,
      highlightClass: 'highlight',
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      when: {
        show: function (this: Shepherd.Step) {
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
          }
        },
      },
      buttons: getStepButtons(indicatorIndex + 1),
    })),
    {
      id: 'final',
      title: t('feedbackPage.finalTitle'),
      text: t('feedbackPage.finalMessage'),
      attachTo: {
        element: '#title',
        on: 'bottom',
      },
      highlightClass: 'highlight',
      classes: [classes.shepherdStep, isRtl ? classes.rtlShepherdStep : ''].join(' '),
      when: {
        show: function (this: Shepherd.Step) {
          if (this.el) {
            this.el.appendChild(renderCloseButton(this.tour))
            this.el.appendChild(renderStepNumber(this.tour.steps.indexOf(this)))
          }
        },
      },
      buttons: [
        ...getStepButtons(data.indicators.message.length + 1),
        {
          text: t('feedbackPage.finish'),
          classes: classes.shepherdFinishButton,
          action: () => {
            tour.complete()
            logCampaignEvent({
              eventType: EVENT_TYPES.FEEDBACK_COMPLETED,
              campaignType: CAMPAIGN_TYPES.EMAIL,
            })
          },
        },
      ],
    },
  ]
  const tour: Shepherd.Tour = new Shepherd.Tour({
    defaultStepOptions: {
      scrollTo: true,
    },
    useModalOverlay: true,
    steps: steps as StepOptions[],
  })
  return tour
}

const buildMicrotraining = (
  simulation: PhishingSimulation,
  language?: string,
  difficulty?: number,
  vector?: string
): CampaignMicrotraining => {
  difficulty = difficulty || simulation.difficulties[0]
  const messageLanguage = language || simulation.languages.message[0]
  const landingPageLanguage = language || simulation.languages.landing_page[0]

  const messageHtml = simulation.html_contents.message
  const landingPageHtml = simulation.html_contents.landing_page

  const buildIndicators: (indicators: PhishingIndicator[]) => MicrotrainingIndicator[] = (indicators) =>
    indicators
      .filter((indicator) => indicator.difficulty === difficulty)
      .map((indicator) => ({
        selector: indicator.selector,
        title: indicator.title[messageLanguage],
        description: indicator.description[messageLanguage],
      }))
  return {
    sender: simulation.sender,
    subject: simulation.subject,
    logo: CywarenessLogo,
    message: {
      html: messageHtml,
      language: messageLanguage,
    },
    landing_page: {
      html: landingPageHtml,
      language: landingPageLanguage,
    },
    indicators: {
      message: buildIndicators(simulation.phishing_indicators?.message),
      landing_page: buildIndicators(simulation.phishing_indicators?.landing_page),
    },
    token: '',
    vector: vector,
  }
}

interface MicrotrainingProps {
  isPreview?: boolean
}

const Microtraining: FC<MicrotrainingProps> = ({ isPreview = false }) => {
  const theme = useTheme()
  const { log: logEvent } = useLogEvent()
  const { t, i18n } = useTranslation()
  const { simulationId, token } = useParams()
  const [isStart, setIsStart] = useState(false)
  const deviceType = useDeviceDetection()
  const {
    data: simulation,
    isPending: isSimulationPending,
    isError: isSimulationError,
  } = usePhishingSimulation(simulationId)

  const {
    data: microtraining,
    isPending: isMicrotrainingPending,
    isError: isMicrotrainingError,
  } = useCampaignMicrotraining(token)

  const loading = isSimulationPending && isMicrotrainingPending
  const error = isSimulationError && isMicrotrainingError
  const attackVector = simulation?.vectors[0] || microtraining?.vector
  const data = useMemo<CampaignMicrotraining | undefined>(
    () => (isPreview && simulation ? buildMicrotraining(simulation) : microtraining),
    [simulation, microtraining]
  )

  const logCampaignEvent =
    isPreview || !data?.token
      ? () => {}
      : (params: Omit<LogEventParams, 'campaignToken'>) => logEvent({ ...params, campaignToken: data.token })

  const tour = createShepherdTour(data, t, isLanguageRTL(data?.message.language), logCampaignEvent)
  useEffect(() => {
    if (data?.message.language) {
      i18n.changeLanguage(data.message.language)
    }
    setIsStart(true)
  }, [data?.message.language, i18n])

  useEffect(() => {
    if (isStart) {
      setTimeout(() => {
        tour?.start()
      })
      setIsStart(false)
    }
  }, [isStart])

  const handleOpenTraining = () => {
    logCampaignEvent({
      eventType: EVENT_TYPES.FEEDBACK_TIPS_CLICKED,
      campaignType: CAMPAIGN_TYPES.EMAIL,
    })
  }
  if (loading) return <LoadingContainer />
  if (error || !data) return <InvalidToken />

  return (
    <Box sx={{ height: '100%' }}>
      <Header logo={CywarnessHeaderLogo} />
      <Box sx={{ width: '90%', margin: 'auto' }}>
        <Box sx={(theme) => ({ padding: theme.spacing(2) })}>
          <Box sx={{ width: '100%', margin: 'auto', textAlign: 'right' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                if (tour) tour.start()
                handleOpenTraining()
              }}
              sx={{ marginBottom: theme.spacing(2), color: theme.palette.black }}>
              {t('feedbackPage.showTips')}
            </Button>
          </Box>
          <DevicePreview
            deviceType={deviceType as DeviceType}
            attackVector={attackVector as PhishingSimulationVector}
            data={data}
            isRtl={isLanguageRTL(data?.message.language)}
          />
        </Box>
      </Box>
    </Box>
  )
}

const StyledStepNumberCircle = styled(Paper)(() => ({
  background: theme.palette.cyan[500],
  width: '22px',
  height: '22px',
  borderRadius: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 12,
}))

const useStyles = makeStyles(() =>
  createStyles({
    shepherdStep: {
      margin: theme.spacing(1),
      maxWidth: '280px',
      borderRadius: '15px',
      '& .shepherd-content': {
        '& .shepherd-header': {
          fontSize: '14px',
          padding: theme.spacing(4, 3, 0),
          '& h3': {
            fontWeight: theme.typography.fontWeightSemiBold,
          },
          fontWeight: theme.typography.fontWeightSemiBold,
          background: 'none',
        },
        '& .shepherd-text': {
          padding: theme.spacing(1, 3, 0),
          maxHeight: '200px',
          overflow: 'scroll',
        },
        '& .shepherd-footer': {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    rtlShepherdStep: {
      direction: 'rtl',
    },
    shepherdButton: {
      padding: theme.spacing(1),
      background: 'none',
      color: theme.palette.black,
      '&:disabled': {
        color: theme.palette.grey[300],
        cursor: 'default',
        '&:hover': {
          color: theme.palette.grey[300],
        },
      },
      '&:not(:disabled):hover': {
        background: 'none',
        color: theme.palette.cyan[500],
      },
      '&:active': {
        background: 'none',
        color: theme.palette.cyan[500],
      },
    },
    shepherdFinishButton: {
      background: theme.palette.cyan[500],
      color: theme.palette.black,
      borderRadius: 100,
      fontSize: 16,
      fontFamily: 'Montserrat',
      fontWeight: theme.typography.fontWeightMedium,
      padding: '6px 16px',
      '&:disabled': {
        color: theme.palette.grey[300],
        cursor: 'default',
        '&:hover': {
          color: theme.palette.grey[300],
        },
      },
      '&:not(:disabled):hover': {
        background: theme.palette.blueDianne[600],
        color: theme.palette.black,
      },
      '&:active': {
        background: theme.palette.blueDianne[800],
        color: theme.palette.black,
      },
    },
    circle: {
      width: '8px',
      height: '8px',
      background: 'white',
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: '50px',
    },
    currentCircle: {
      width: '8px',
      height: '8px',
      background: theme.palette.cyan[500],
      border: `1px solid ${theme.palette.cyan[500]}`,
      borderRadius: '50px',
    },
    circles: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  })
)

export default Microtraining
