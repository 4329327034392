import { useState, MouseEvent } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme/theme'

type MoreMenuProps = {
  ariaLabel: string
  items: { label: string; onClick: Function; textColor?: string; fontWeight?: string; disabled?: boolean }[]
  disabled?: boolean
}

export default function MoreMenu({ ariaLabel, items, disabled }: MoreMenuProps) {
  const { t } = useTranslation()
  const [anchorElement, setAnchorElement] = useState<null | Element>(null)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorElement(null)
  }

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        aria-label={t(ariaLabel)}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faEllipsisVertical as IconProp} />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem
            style={{
              color: item.textColor ? item.textColor : theme.palette.text.primary,
              fontWeight: item.fontWeight,
            }}
            key={index}
            onClick={(event) => {
              if (!item.disabled) {
                event.stopPropagation()
                item.onClick()
                handleClose(event)
              }
            }}
            disabled={item.disabled}
          >
            {t(item.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
